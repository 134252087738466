import React, { Fragment, useEffect, useState } from "react";
import { InfoCircleTwoTone } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "antd";
import moment from "moment";
import {
  setTableListData,
  setTableActionDetails
} from "../../actions/tableListing/tableListDataAction";

const AssumptionEtaTab = ({
  tabTableData,
  isLoading,
  OnhandleTableSortColumn
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isO2WPage =
  window.location.pathname.split("/")[1] === "dashboard" ? true : false;
  
  const tableSorting = useSelector(
    (state) => state.TableSortingData.tableListData
  );

  const [totalRecords, setTotalRecords] = useState(0);
  const [pageNo, setPageNo] = useState(1);

  useEffect(() => {
    if (tabTableData.assumptionTabsList?.length > 0) {
      setTotalRecords(tabTableData.totalRecords);
      setPageNo(Number(tabTableData.currentPage));
      dispatch(setTableListData(tabTableData.assumptionTabsList));
    } else {
      setTotalRecords(0);
      setPageNo(1);
    }
  }, [tabTableData.assumptionTabsList]);

  const handleTableAscDes = (pagination, filters, sorter) => {
    const sortOrder = sorter.order;
    const sortField = sorter.field;
    OnhandleTableSortColumn({
      sortColumn: sortField,
      orderBy:
        sortOrder === "ascend"
          ? "asc"
          : sortOrder === "descend"
          ? "desc"
          : "asc",
      currentPage: pagination.current
    });
  };

  const handleOverviewDetailsPage = (rowData) => {
    dispatch(
      setTableActionDetails({
        labelId: rowData.customer_PO_Number,
        valueName: rowData.customer_PO_Number,
        SapOrderNumber: rowData.sap_Order_Number
      })
    );
    navigate(
      `/${isO2WPage ? "o2w" : "logistic"}/overviewDetails/assumption/${rowData.customer_PO_Number.replace(
        /[ \/]/g,
        ""
      )}`
    );
  };

  const tableColumns = [
    {
      title: "Invoice Number",
      dataIndex: "invoice_Number",
      sorter: true,
      showSorterTooltip: false,
      width: 190,
      sortOrder:
        tableSorting.sortColumn === "invoice_Number"
          ? tableSorting.orderBy === "asc"
            ? "ascend"
            : "descend"
          : null,
      render: (_i, row) => {
        return (
          <div>
            <Link to={`/detailsView/assumption/${row.sap_Order_Number}`}>
              <label className="cust_po_no_lbl">{row.invoice_Number}</label>
            </Link>
          </div>
        );
      }
    },
    {
      title: "Estimated Plan Date",
      dataIndex: "estimated_Plan_Date",
      sorter: true,
      showSorterTooltip: false,
      sortOrder:
        tableSorting.sortColumn === "estimated_Plan_Date"
          ? tableSorting.orderBy === "asc"
            ? "ascend"
            : "descend"
          : null,
      width: 200,
      render: (_i, row) => {
        return (
          <div>
            {row.estimated_Plan_Date
              ? moment(row.estimated_Plan_Date).format("DD-MM-YYYY")
              : "N/A"}
          </div>
        );
      }
    },
    {
      title: "Customer Ref No",
      dataIndex: "customer_PO_Number",
      sorter: true,
      showSorterTooltip: false,
      sortOrder:
        tableSorting.sortColumn === "customer_PO_Number"
          ? tableSorting.orderBy === "asc"
            ? "ascend"
            : "descend"
          : null,
      width: 200
    },
    {
      title: "Customer Organization Name",
      dataIndex: "customer_Organization_Name",
      sorter: true,
      showSorterTooltip: false,
      width: 280,
      sortOrder:
        tableSorting.sortColumn === "customer_Organization_Name"
          ? tableSorting.orderBy === "asc"
            ? "ascend"
            : "descend"
          : null,
      render: (_i, row) => {
        return (
          <div>
            <label>
              {row.customer_Organization_Name}
            </label>
          </div>
        );
      }
    },
    {
      title: "customer Ref Date",
      dataIndex: "customer_PO_Date",
      sorter: true,
      showSorterTooltip: false,
      sortOrder:
        tableSorting.sortColumn === "customer_PO_Date"
          ? tableSorting.orderBy === "asc"
            ? "ascend"
            : "descend"
          : null,
      width: 200,
      render: (_i, row) => {
        return (
          <div>
            {row.customer_PO_Date
              ? moment(row.customer_PO_Date).format("DD-MM-YYYY")
              : "N/A"}
          </div>
        );
      }
    },
    {
      title: "SAP Order No",
      dataIndex: "sap_Order_Number",
      sorter: true,
      showSorterTooltip: false,
      sortOrder:
        tableSorting.sortColumn === "sap_Order_Number"
          ? tableSorting.orderBy === "asc"
            ? "ascend"
            : "descend"
          : null,
      width: 200
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 80,
      fixed: "right",
      render: (_i, row) => (
        <div
          className="tbl_info_details"
          onClick={() => handleOverviewDetailsPage(row)}
        >
          <InfoCircleTwoTone />
        </div>
      )
    }
  ];
  return (
    <Fragment>
      <Table
        bordered
        scroll={{ x: 900 }}
        className="dashboard_tables"
        dataSource={tabTableData.assumptionTabsList}
        loading={isLoading}
        onChange={handleTableAscDes}
        pagination={{
          total: totalRecords,
          pageNo: pageNo,
          pageSize: 10,
          responsive: true,
          showSizeChanger: false,
          current: pageNo,
          onChange: (pageNum) => setPageNo(pageNum)
        }}
        columns={tableColumns}
      />
    </Fragment>
  );
};

export default AssumptionEtaTab;
