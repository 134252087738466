import {
  SET_TABLELISTDATA,
  SET_TABLEACTIONDETAILS
} from "./tableListDataTypes";

export const setTableListData = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_TABLELISTDATA,
      payload: data
    });
  } catch (err) {}
};
export const setTableActionDetails = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_TABLEACTIONDETAILS,
      payload: data
    });
  } catch (err) {}
};
