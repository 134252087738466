import React, { Fragment, useState, useEffect } from "react";
import { imgCubes } from "./imageAssets";
import { useNavigate } from "react-router-dom";

const EliteCard = () => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const handleCardClick = () => {
    navigate("/eliteDashboard");
  };

  return (
    <Fragment>
      <div className="dashboard app-content content">
        <div className="content-wrapper">
          <div className="content-body">
            <div className="row">
              <div className="col-xl-3 col-lg-6 col-12">
                <div
                  className={`card card-hover cur_point ${
                    isHovered ? "hovered" : ""
                  }`}
                  onClick={handleCardClick}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <div className="card-content">
                    <div className="card-body d-flex justify-content-between">
                      <img src={imgCubes} alt="img" />
                      <h4 className="text-info pt-15">Elite</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EliteCard;
