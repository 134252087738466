import {
  SET_TABLESORTINGDATA,
  SET_TABLESEARCHDATA
} from "../../actions/tableListing/tableListDataTypes";

const initialState = {
  tableSortingData: null,
  tableSearchInput:""
};

function TableSortingData(tableState = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_TABLESORTINGDATA:
      return { ...tableState, tableListData: payload };

    case SET_TABLESEARCHDATA:
      return { ...tableState, tableSearchInput: payload };

    default:
      return tableState;
  }
}

export default TableSortingData;
