import React from "react";
import "./layout.css";

const Footer = () => {
	return (
		<React.Fragment>
			<div className="footer">
				<p className=" blue-grey  mb-0  py-2">
					<label className="float-md-left d-block d-md-inline-block">
						Copyright &copy; 2024
						<span className="font-weight-bold px-1">Knack Packaging</span>, All
						rights reserved.
					</label>
				</p>
			</div>
		</React.Fragment>
	);
};

export default Footer;
