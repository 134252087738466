import React, { useState } from "react";
import Sidemenu from "./sidemenu";
import Header from "./header";
import Footer from "./footer";
import "./layout.css";

const Layout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [mobileMenuToggle, setMobileMenuToggle] = useState(true);

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  const toggleMobileMenuCollapsed = () => {
    setMobileMenuToggle(!mobileMenuToggle);
  };
  return (
    <React.Fragment>
      <div
        className={
          window.innerWidth <= 767
            ? `layout_container ${mobileMenuToggle ? "sidebar_open" : ""}`
            : `layout_container ${collapsed ? "sidebar_open" : ""}`
        }
      >
        <div className="sidebar_section">
          <Sidemenu
            collapsed={collapsed}
            toggleMobileMenuCollapsed={toggleMobileMenuCollapsed}
          />
        </div>
        <div className="header_section">
          <Header
            collapsed={collapsed}
            toggleSidebar={toggleSidebar}
            mobileMenuToggle={mobileMenuToggle}
            toggleMobileMenuCollapsed={toggleMobileMenuCollapsed}
          />
        </div>
        <div className="content_area">
          <div className="content_area_section">{children}</div>
        </div>
        <div className="footer_section">
          <Footer />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Layout;
