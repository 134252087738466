import { httpTokenCommon } from "../helper/http-common";

class OverTimeScheduleService {
  getQuantityCountsDataAPI(inputData) {
    return httpTokenCommon.post("OverViewTab/GetOrderQauntityAsync", inputData);
  }
  overviewGetOrderandInvoiceDataAPI(inputData) {
    return httpTokenCommon.post(
      "OverViewTab/GetEAAndDeliveryPerInvoiceDataAsync",
      inputData
    );
  }
  overviewGetDayandTimelineChartDataAPI(inputData) {
    return httpTokenCommon.post(
      "OverViewTab/GetDelayAnalysisAndTimelineChartDataAsync",
      inputData
    );
  }
  getTimelineResultsDataAPI(inputData) {
    return httpTokenCommon.post("Timeline/GetTimeLineResultAsync", inputData);
  }
  timelineUploadInvoiceDocAPI(inputData) {
    return httpTokenCommon.post("Timeline/UploadInvoiceDocuments", inputData);
  }

  getMaterialDropDownDataAPI(inputData) {
    return httpTokenCommon.post(
      "Timeline/GetCustomerMaterialAndPonumberAsync",
      inputData
    );
  }
}

const overTimeScheduleService = new OverTimeScheduleService();
export default overTimeScheduleService;
