import React, { Fragment, useEffect, useState } from "react";
import { Table } from "antd";
import moment from "moment";
import formatNumber from "../../../../utils/formatNumber";

const InvoiceDetailsTbl = ({
  invoiceDetailsData,
  isLoading,
  OnhandleTableSortColumn,
  isEmployee,
}) => {
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageNo, setPageNo] = useState(1);

  useEffect(() => {
    if (invoiceDetailsData?.customerInvoiceList?.length > 0) {
      setTotalRecords(invoiceDetailsData.totalRecords);
      setPageNo(Number(invoiceDetailsData.currentPage));
    } else {
      setTotalRecords(0);
      setPageNo(1);
    }
  }, [invoiceDetailsData.customerInvoiceList]);

  //// handle table Asc and Dec code
  const handleTableAscDes = (pagination, filters, sorter) => {
    const sortOrder = sorter.order;
    const sortField = sorter.field;
    OnhandleTableSortColumn({
      currentPage: pagination.current,
      table: "Invoice Details",
      sortColumn: sortField,
      orderBy:
        sortOrder === "ascend"
          ? "asc"
          : sortOrder === "descend"
          ? "desc"
          : "asc",
    });
  };

  const parentTableColumns = [
    {
      title: "Customer",
      dataIndex: "customerOrganization",
      sorter: (a, b) => a.customerOrganization - b.customerOrganization,
      showSorterTooltip: false,
      width: 230,
      fixed: "left",
      render: (_i, row) => {
        return (
          <div>
            <label className="cust_po_no_lbl">{row.customerOrganization}</label>
          </div>
        );
      },
    },

    {
      title: isEmployee ? "Pending Amount LCY" : "Pending Amount",
      dataIndex: "totalPendingAmount_LCY",
      sorter: (a, b) =>
        parseFloat((a.totalPendingAmount_LCY || "0").replace(/,/g, "")) -
        parseFloat((b.totalPendingAmount_LCY || "0").replace(/,/g, "")),
      showSorterTooltip: false,
      width: 160,
      render: (text, record) =>
        `${formatNumber(
          parseFloat((record.totalPendingAmount_LCY || "0").replace(/,/g, ""))
        )}`,
    },
    {
      title: "Invoice Amount",
      dataIndex: "totalInvoiceAmt",
      sorter: (a, b) =>
        parseFloat((a.totalInvoiceAmt || "0").replace(/,/g, "")) -
        parseFloat((b.totalInvoiceAmt || "0").replace(/,/g, "")),
      showSorterTooltip: false,
      width: 160,
      render: (text, record) =>
        `${formatNumber(
          parseFloat((record.totalInvoiceAmt || "0").replace(/,/g, ""))
        )}`,
    },
    {
      title: "Undue Amount",
      dataIndex: "totalundamt",
      sorter: (a, b) =>
        parseFloat((a.totalundamt || "0").replace(/,/g, "")) -
        parseFloat((b.totalundamt || "0").replace(/,/g, "")),
      showSorterTooltip: false,
      width: 160,
      render: (text, record) =>
        `${formatNumber(
          parseFloat((record.totalundamt || "0").replace(/,/g, ""))
        )}`,
    },
    {
      title: "Unadjusted Amount",
      dataIndex: "totalunadjamt",
      sorter: (a, b) =>
        parseFloat((a.totalunadjamt || "0").replace(/,/g, "")) -
        parseFloat((b.totalunadjamt || "0").replace(/,/g, "")),
      showSorterTooltip: false,
      width: 160,
      render: (text, record) =>
        `${formatNumber(
          parseFloat((record.totalunadjamt || "0").replace(/,/g, ""))
        )}`,
    },
    {
      title: "Pending Amount",
      dataIndex: "totalPendingAmount",
      sorter: (a, b) =>
        parseFloat((a.totalPendingAmount || "0").replace(/,/g, "")) -
        parseFloat((b.totalPendingAmount || "0").replace(/,/g, "")),
      showSorterTooltip: false,
      width: 160,
      render: (text, record) =>
        `${formatNumber(
          parseFloat((record.totalPendingAmount || "0").replace(/,/g, ""))
        )}`,
    },
    {
      title: "Total Credit Limit",
      dataIndex: "totalcredit",
      sorter: (a, b) =>
        parseFloat((a.totalcredit || "0").replace(/,/g, "")) -
        parseFloat((b.totalcredit || "0").replace(/,/g, "")),
      showSorterTooltip: false,
      width: 160,
      render: (text, record) =>
        `${formatNumber(
          parseFloat((record.totalcredit || "0").replace(/,/g, ""))
        )}`,
    },
    {
      title: "Credit Days",
      dataIndex: "totalcreditdays",
      sorter: (a, b) =>
        parseFloat((a.totalcreditdays || "0").replace(/,/g, "")) -
        parseFloat((b.totalcreditdays || "0").replace(/,/g, "")),
      showSorterTooltip: false,
      width: 160,
      render: (_i, row) => {
        return <div>{row.totalcreditdays}</div>;
      },
    },
    {
      title: "Overdue Days",
      dataIndex: "totaloverdued",
      sorter: (a, b) =>
        parseFloat((a.totaloverdued || "0").replace(/,/g, "")) -
        parseFloat((b.totaloverdued || "0").replace(/,/g, "")),
      showSorterTooltip: false,
      width: 160,
      render: (_i, row) => {
        return <div>{row.totaloverdued}</div>;
      },
    },
    {
      title: "Today Total Days",
      dataIndex: "totaltodaytot",
      sorter: (a, b) =>
        parseFloat((a.totaltodaytot || "0").replace(/,/g, "")) -
        parseFloat((b.totaltodaytot || "0").replace(/,/g, "")),
      showSorterTooltip: false,
      width: 160,
      render: (_i, row) => {
        return <div>{row.totaltodaytot}</div>;
      },
    },
    {
      title: "0-10 Days",
      dataIndex: "totalfirst",
      showSorterTooltip: false,
      width: 120,
    },
    {
      title: "11-15 Days",
      dataIndex: "totalsecond",
      showSorterTooltip: false,
      width: 120,
    },
    {
      title: "16-30 Days",
      dataIndex: "totalthird",
      showSorterTooltip: false,
      width: 120,
    },
    {
      title: "31-45 Days",
      dataIndex: "totalfourth",
      showSorterTooltip: false,
      width: 120,
    },
    {
      title: "46-60 Days",
      dataIndex: "totalfifth",
      showSorterTooltip: false,
      width: 120,
    },
    {
      title: "61-75 Days",
      dataIndex: "totalsixth",
      sorter: (a, b) =>
        parseFloat(a["totalsixth"].replace(/,/g, "")) -
        parseFloat(b["totalsixth"].replace(/,/g, "")),
      showSorterTooltip: false,
      width: 120,
      render: (_i, row) => {
        return (
          <div>
            {row.totalsixth !== ""
              ? formatNumber(
                  parseFloat((row.totalsixth || "0").replace(/,/g, ""))
                )
              : "-"}
          </div>
        );
      },
    },
    {
      title: "76-90 Days",
      dataIndex: "totalseventh",
      sorter: (a, b) =>
        parseFloat(a["totalseventh"].replace(/,/g, "")) -
        parseFloat(b["totalseventh"].replace(/,/g, "")),
      showSorterTooltip: false,
      width: 120,
      render: (_i, row) => {
        return (
          <div>
            {row.totalseventh !== ""
              ? formatNumber(
                  parseFloat((row.totalseventh || "0").replace(/,/g, ""))
                )
              : "-"}
          </div>
        );
      },
    },
    {
      title: ">90 Days",
      dataIndex: "totaleighth",
      sorter: (a, b) =>
        parseFloat(a["totaleighth"].replace(/,/g, "")) -
        parseFloat(b["totaleighth"].replace(/,/g, "")),
      showSorterTooltip: false,
      width: 120,
      render: (_i, row) => {
        return (
          <div>
            {row.totaleighth !== ""
              ? formatNumber(
                  parseFloat((row.totaleighth || "0").replace(/,/g, ""))
                )
              : "-"}
          </div>
        );
      },
    },
  ];
  // const tableColumns = [
  //   {
  //     title: "Customer",
  //     dataIndex: "customerOrganization",
  //     sorter: (a, b) => a.customerOrganization - b.customerOrganization,
  //     showSorterTooltip: false,
  //     width: 230,
  //     fixed: "left",
  //     render: (_i, row) => {
  //       return (
  //         <div>
  //           <label className="cust_po_no_lbl">{row.customerOrganization}</label>
  //         </div>
  //       );
  //     },
  //   },
  //   {
  //     title: "Invoice No",
  //     dataIndex: "invoiceNo",
  //     sorter: (a, b) =>
  //       parseFloat(a.invoiceNo.replace(/,/g, "")) -
  //       parseFloat(b.invoiceNo.replace(/,/g, "")),
  //     showSorterTooltip: false,
  //     width: 160,
  //     fixed: "left",
  //   },
  //   {
  //     title: "Invoice Date",
  //     dataIndex: "invoiceDate",
  //     sorter: (a, b) => new Date(a.invoiceDate) - new Date(b.invoiceDate),
  //     showSorterTooltip: false,
  //     width: 120,
  //     render: (_i, row) => {
  //       return (
  //         <div>
  //           {row.invoiceDate
  //             ? moment(row.invoiceDate).format("DD-MM-YYYY")
  //             : "N/A"}
  //         </div>
  //       );
  //     },
  //   },
  //   {
  //     title: "Due Date",
  //     dataIndex: "duedt",
  //     sorter: (a, b) => new Date(a.duedt) - new Date(b.duedt),
  //     showSorterTooltip: false,
  //     width: 120,
  //     render: (_i, row) => {
  //       return (
  //         <div>
  //           {row.duedt ? moment(row.duedt).format("DD-MM-YYYY") : "N/A"}
  //         </div>
  //       );
  //     },
  //   },
  //   {
  //     title: isEmployee ? "Pending Amount LCY" : "Pending Amount",
  //     dataIndex: "totalPendingAmount_LCY",
  //     sorter: (a, b) =>
  //       parseFloat(a.totalPendingAmount_LCY.replace(/,/g, "")) -
  //       parseFloat(b.totalPendingAmount_LCY.replace(/,/g, "")),
  //     showSorterTooltip: false,
  //     width: 160,
  //     render: (text, record) =>
  //       `${formatNumber(
  //         parseFloat(record.totalPendingAmount_LCY.replace(/,/g, ""))
  //       )}`,
  //   },
  //   {
  //     title: "Invoice Amount",
  //     dataIndex: "invoiceAmt",
  //     sorter: (a, b) =>
  //       parseFloat(a.invoiceAmt.replace(/,/g, "")) -
  //       parseFloat(b.invoiceAmt.replace(/,/g, "")),
  //     showSorterTooltip: false,
  //     width: 160,
  //     render: (text, record) =>
  //       `${record.currency} ${formatNumber(
  //         parseFloat(record.invoiceAmt.replace(/,/g, ""))
  //       )}`,
  //   },
  //   {
  //     title: "Undue Amount",
  //     dataIndex: "undamt",
  //     sorter: (a, b) =>
  //       parseFloat(a.undamt.replace(/,/g, "")) -
  //       parseFloat(b.undamt.replace(/,/g, "")),
  //     showSorterTooltip: false,
  //     width: 160,
  //     render: (text, record) =>
  //       `${record.currency} ${formatNumber(
  //         parseFloat(record.undamt.replace(/,/g, ""))
  //       )}`,
  //   },
  //   {
  //     title: "Unadjusted Amount",
  //     dataIndex: "unadjamt",
  //     sorter: (a, b) =>
  //       parseFloat(a.unadjamt.replace(/,/g, "")) -
  //       parseFloat(b.unadjamt.replace(/,/g, "")),
  //     showSorterTooltip: false,
  //     width: 160,
  //     render: (text, record) =>
  //       `${record.currency} ${formatNumber(
  //         parseFloat(record.unadjamt.replace(/,/g, ""))
  //       )}`,
  //   },
  //   {
  //     title: "Pending Amount",
  //     dataIndex: "totalPendingAmount",
  //     sorter: (a, b) =>
  //       parseFloat(a.totalPendingAmount.replace(/,/g, "")) -
  //       parseFloat(b.totalPendingAmount.replace(/,/g, "")),
  //     showSorterTooltip: false,
  //     width: 160,
  //     render: (text, record) =>
  //       `${record.currency} ${formatNumber(
  //         parseFloat(record.totalPendingAmount.replace(/,/g, ""))
  //       )}`,
  //   },
  //   {
  //     title: "Total Credit Limit",
  //     dataIndex: "totalcredit",
  //     sorter: (a, b) =>
  //       parseFloat(a.totalcredit.replace(/,/g, "")) -
  //       parseFloat(b.totalcredit.replace(/,/g, "")),
  //     showSorterTooltip: false,
  //     width: 160,
  //     render: (text, record) =>
  //       `${record.currency} ${formatNumber(
  //         parseFloat(record.totalcredit.replace(/,/g, ""))
  //       )}`,
  //   },
  //   {
  //     title: "Credit Days",
  //     dataIndex: "creditdays",
  //     sorter: (a, b) =>
  //       parseFloat(a.creditdays.replace(/,/g, "")) -
  //       parseFloat(b.creditdays.replace(/,/g, "")),
  //     showSorterTooltip: false,
  //     width: 160,
  //     render: (_i, row) => {
  //       return <div>{row.creditdays}</div>;
  //     },
  //   },
  //   {
  //     title: "Overdue Days",
  //     dataIndex: "overdued",
  //     sorter: (a, b) =>
  //       parseFloat(a.overdued.replace(/,/g, "")) -
  //       parseFloat(b.overdued.replace(/,/g, "")),
  //     showSorterTooltip: false,
  //     width: 160,
  //     render: (_i, row) => {
  //       return <div>{row.overdued}</div>;
  //     },
  //   },
  //   {
  //     title: "Today Total Days",
  //     dataIndex: "todaytot",
  //     sorter: (a, b) =>
  //       parseFloat(a.todaytot.replace(/,/g, "")) -
  //       parseFloat(b.todaytot.replace(/,/g, "")),
  //     showSorterTooltip: false,
  //     width: 160,
  //     render: (_i, row) => {
  //       return <div>{row.todaytot}</div>;
  //     },
  //   },
  //   {
  //     title: "0-10 Days",
  //     dataIndex: "first",
  //     showSorterTooltip: false,
  //     width: 120,
  //   },
  //   {
  //     title: "11-15 Days",
  //     dataIndex: "second",
  //     showSorterTooltip: false,
  //     width: 120,
  //   },
  //   {
  //     title: "16-30 Days",
  //     dataIndex: "third",
  //     showSorterTooltip: false,
  //     width: 120,
  //   },
  //   {
  //     title: "31-45 Days",
  //     dataIndex: "fourth",
  //     showSorterTooltip: false,
  //     width: 120,
  //   },
  //   {
  //     title: "46-60 Days",
  //     dataIndex: "fifth",
  //     showSorterTooltip: false,
  //     width: 120,
  //   },
  //   {
  //     title: "61-75 Days",
  //     dataIndex: "sixth",
  //     sorter: (a, b) =>
  //       parseFloat(a["sixth"].replace(/,/g, "")) -
  //       parseFloat(b["sixth"].replace(/,/g, "")),
  //     showSorterTooltip: false,
  //     width: 120,
  //     render: (_i, row) => {
  //       return (
  //         <div>
  //           {row.sixth !== ""
  //             ? formatNumber(parseFloat(row.sixth.replace(/,/g, "")))
  //             : "-"}
  //         </div>
  //       );
  //     },
  //   },
  //   {
  //     title: "76-90 Days",
  //     dataIndex: "seventh",
  //     sorter: (a, b) =>
  //       parseFloat(a["seventh"].replace(/,/g, "")) -
  //       parseFloat(b["seventh"].replace(/,/g, "")),
  //     showSorterTooltip: false,
  //     width: 120,
  //     render: (_i, row) => {
  //       return (
  //         <div>
  //           {row.seventh !== ""
  //             ? formatNumber(parseFloat(row.seventh.replace(/,/g, "")))
  //             : "-"}
  //         </div>
  //       );
  //     },
  //   },
  //   {
  //     title: ">90 Days",
  //     dataIndex: "eighth",
  //     sorter: (a, b) =>
  //       parseFloat(a["eighth"].replace(/,/g, "")) -
  //       parseFloat(b["eighth"].replace(/,/g, "")),
  //     showSorterTooltip: false,
  //     width: 120,
  //     render: (_i, row) => {
  //       return (
  //         <div>
  //           {row.eighth !== ""
  //             ? formatNumber(parseFloat(row.eighth.replace(/,/g, "")))
  //             : "-"}
  //         </div>
  //       );
  //     },
  //   },
  // ];

  return (
    <Fragment>
      <Table
        bordered
        scroll={{ x: 900 }}
        className="dashboard_tables payment_table"
        dataSource={invoiceDetailsData?.customerInvoiceList}
        loading={isLoading}
        rowKey="uniqueKey"
        columns={parentTableColumns}
        onChange={handleTableAscDes}
        pagination={{
          total: totalRecords,
          pageNo: pageNo,
          pageSize: 10,
          responsive: true,
          showSizeChanger: false,
          current: pageNo,
          onChange: (pageNum) => setPageNo(pageNum),
        }}
        summary={() => {
          return (
            <Table.Summary.Row className="tbl_summary">
              {/* <Table.Summary.Cell index={0} /> */}
              <Table.Summary.Cell style={{ fontWeight: "bold" }} index={1}>
                Total
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {formatNumber(invoiceDetailsData.total)}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {formatNumber(invoiceDetailsData.totalInvoiceamtTotal)}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {formatNumber(invoiceDetailsData.totalUndamtTotal)}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {formatNumber(invoiceDetailsData.totalUnadjamtTotal)}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {formatNumber(invoiceDetailsData.totalPendingAmount)}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {formatNumber(invoiceDetailsData.totalcreditTotal)}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {formatNumber(invoiceDetailsData.totalCreditdaysTotal)}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {formatNumber(invoiceDetailsData.totalOverDuedaysTotal)}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {formatNumber(invoiceDetailsData.totalTodaytotTotal)}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {formatNumber(invoiceDetailsData.fifthTotal)}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {formatNumber(invoiceDetailsData.secondTotal)}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {formatNumber(invoiceDetailsData.thirdTotal)}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {formatNumber(invoiceDetailsData.fourthTotal)}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {formatNumber(invoiceDetailsData.fifthTotal)}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {formatNumber(invoiceDetailsData.sixthTotal)}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {formatNumber(invoiceDetailsData.seventhTotal)}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {formatNumber(invoiceDetailsData.eighthTotal)}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
        // expandable={{
        //   expandedRowRender: (record) => (
        //     <Table
        //       columns={tableColumns}
        //       dataSource={record.customerAgeingList}
        //       rowKey="uniqueKey"
        //       pagination={false}
        //       scroll={{ x: 900, y: 200 }}
        //       summary={() => {
        //         return (
        //           <Table.Summary.Row className="tbl_summary">
        //             <Table.Summary.Cell index={0} />
        //             <Table.Summary.Cell
        //               style={{ fontWeight: "bold" }}
        //               index={1}
        //             >
        //               Total
        //             </Table.Summary.Cell>
        //             <Table.Summary.Cell index={2} />
        //             <Table.Summary.Cell index={3} />
        //             <Table.Summary.Cell>
        //               {formatNumber(record.totalPendingAmount_LCY)}
        //             </Table.Summary.Cell>
        //             <Table.Summary.Cell>
        //               {formatNumber(record.totalInvoiceAmt)}
        //             </Table.Summary.Cell>
        //             <Table.Summary.Cell>
        //               {formatNumber(record.totalundamt)}
        //             </Table.Summary.Cell>
        //             <Table.Summary.Cell>
        //               {formatNumber(record.totalunadjamt)}
        //             </Table.Summary.Cell>
        //             <Table.Summary.Cell>
        //               {formatNumber(record.totalPendingAmount)}
        //             </Table.Summary.Cell>
        //             <Table.Summary.Cell>
        //               {formatNumber(record.totalcredit)}
        //             </Table.Summary.Cell>
        //             <Table.Summary.Cell>
        //               {formatNumber(record.totalcreditdays)}
        //             </Table.Summary.Cell>
        //             <Table.Summary.Cell>
        //               {formatNumber(record.totaloverdued)}
        //             </Table.Summary.Cell>
        //             <Table.Summary.Cell>
        //               {formatNumber(record.totaltodaytot)}
        //             </Table.Summary.Cell>
        //             <Table.Summary.Cell>
        //               {formatNumber(record.totalfirst)}
        //             </Table.Summary.Cell>
        //             <Table.Summary.Cell>
        //               {formatNumber(record.totalsecond)}
        //             </Table.Summary.Cell>
        //             <Table.Summary.Cell>
        //               {formatNumber(record.totalthird)}
        //             </Table.Summary.Cell>
        //             <Table.Summary.Cell>
        //               {formatNumber(record.totalfourth)}
        //             </Table.Summary.Cell>
        //             <Table.Summary.Cell>
        //               {formatNumber(record.totalfifth)}
        //             </Table.Summary.Cell>
        //             <Table.Summary.Cell>
        //               {formatNumber(record.totalsixth)}
        //             </Table.Summary.Cell>
        //             <Table.Summary.Cell>
        //               {formatNumber(record.totalseventh)}
        //             </Table.Summary.Cell>
        //             <Table.Summary.Cell>
        //               {formatNumber(record.totaleighth)}
        //             </Table.Summary.Cell>
        //           </Table.Summary.Row>
        //         );
        //       }}
        //     />
        //   ),
        //   rowExpandable: (record) =>
        //     record.customerAgeingList && record.customerAgeingList.length > 0,
        // }}
      />
    </Fragment>
  );
};

export default InvoiceDetailsTbl;
