import CryptoJS from "crypto-js";

export default function useEncryptData() {
  const secretKey = "knackPackaging";

  function EncryptData(token) {
    const encrypted = CryptoJS.AES.encrypt(token, secretKey.substring(0, 16)).toString();
    return encrypted;
  }
  return EncryptData;
}
