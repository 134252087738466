import { httpTokenCommon } from "../helper/http-common";

class DashboardService {
  getTabsDashboardDataAPI(inputData) {
    return httpTokenCommon.post("Dashboard/GetTabsDashboardData", inputData);
  }
  getAllDetailsDashboardAPI(inputData) {
    return httpTokenCommon.post("Dashboard/GetAllTabsDetailsData", inputData);
  }
  getPoDetailsDashboardAPI(inputData) {
    return httpTokenCommon.post("Dashboard/GetPOTabsDetailsData", inputData);
  }
  getMaterialDetailsDashboardAPI(inputData) {
    return httpTokenCommon.post("Dashboard/GetMaterialDetailsData", inputData);
  }
  getInvoiceDetailsDashboardAPI(inputData) {
    return httpTokenCommon.post("Dashboard/GetInvoiceDetailsData", inputData);
  }
  getVehicleContainerDashboardAPI(inputData) {
    return httpTokenCommon.post(
      "Dashboard/GetVehicleContainerDetailsData",
      inputData
    );
  }
  getAssumptionETADashboardAPI(inputData) {
    return httpTokenCommon.post(
      "Dashboard/GetAssumptionDetailsData",
      inputData
    );
  }
}

const dashboardService = new DashboardService();
export default dashboardService;
