// // ALL Tab details view Data
export function TabAllDetailsData(key) {
  const AllData = [
    {
      label: "Customer PO Number",
      APILabel: "customer_PO_Number",
      isDate: false
    },
    {
      label: "Customer PO Date",
      APILabel: "customer_PO_Date",
      isDate: true
    },
    {
      label: "Sap Order Number",
      APILabel: "sap_Order_Number",
      isDate: false
    },
    {
      label: "Knack Schedule SO Date",
      APILabel: "knack_Schedule_SO_Date",
      isDate: true
    },
    {
      label: "Material Name",
      APILabel: "material_Name",
      isDate: false
    },
    { label: "Sale Unit", APILabel: "sale_Unit", isDate: false },
    {
      label: "Sale Quantity",
      APILabel: "sale_Quantity",
      isDate: false
    },
    { label: "Invoice No", APILabel: "inV_NO", isDate: false },
    { label: "Invoice Date", APILabel: "inV_DAT", isDate: true },
    { label: "Dispatch Qty", APILabel: "dispatch_Qty", isDate: false },
    {
      label: "Dispatch Unit",
      APILabel: "dispatch_Unit",
      isDate: false
    },
    {
      label: "Mode of Transport",
      APILabel: "modE_OF_TANSPOR",
      isDate: false
    },
    { label: "BL Number", APILabel: "bl_Number", isDate: false },
    { label: "BL Date", APILabel: "bl_Date", isDate: true },
    { label: "LR Number", APILabel: "lR_Number", isDate: false },
    { label: "LR Date", APILabel: "lR_Date", isDate: true },
    {
      label: "Vehicle Number",
      APILabel: "vehicle_Number",
      isDate: false
    },
    {
      label: "Container Number",
      APILabel: "container_Number",
      isDate: false
    },
    {
      label: "Estimated Plan Date",
      APILabel: "estimated_Plan_Date",
      isDate: true
    },
    {
      label: "Schedule Number",
      APILabel: "schedule_Number",
      isDate: false
    },
    {
      label: "Order Status Description",
      APILabel: "order_Status_Description",
      isDate: false
    }
  ];

  let AllObjData = AllData.find((x) => x.APILabel === key);

  return AllObjData;
}

// // PO Tab details view Data
export function TabPoDetailsData(key) {
  const PoData = [
    {
      label: "Customer PO Number",
      APILabel: "customer_PO_Number",
      isDate: false
    },
    {
      label: "Customer PO Date",
      APILabel: "customer_PO_Date",
      isDate: true
    },
    {
      label: "Material Name",
      APILabel: "material_Name",
      isDate: false
    },
    {
      label: "Material SAP Code",
      APILabel: "material_SAP_Code",
      isDate: false
    },
    {
      label: "SAP Order Number",
      APILabel: "sap_Order_Number",
      isDate: false
    },
    {
      label: "Knack Schedule SO Date",
      APILabel: "knack_Schedule_SO_Date",
      isDate: true
    },
    {
      label: "Sale Unit",
      APILabel: "sale_Unit",
      isDate: false
    },
    {
      label: "Sale Quantity",
      APILabel: "sale_Quantity",
      isDate: false
    },
    {
      label: "Estimated Plan Date",
      APILabel: "estimated_Plan_Date",
      isDate: true
    },
    {
      label: "Mode Of Tansport",
      APILabel: "mode_Of_Tansport",
      isDate: false
    },
    {
      label: "Proposed Shipment Booking Date",
      APILabel: "proposed_Shipment_Booking_Date",
      isDate: true
    },
    {
      label: "Invoice Number",
      APILabel: "invoice_Number",
      isDate: false
    },
    {
      label: "Invoice Date",
      APILabel: "invoice_Date",
      isDate: true
    },
    {
      label: "Order Status Description",
      APILabel: "order_Status_Description",
      isDate: false
    },
    {
      label: "Till Date Dispatched Qty",
      APILabel: "till_Date_Dispatched_Qty",
      isDate: false
    },
    {
      label: "Balance Quantity",
      APILabel: "balance_Quantity",
      isDate: false
    },
    {
      label: "BL Date",
      APILabel: "bl_Date",
      isDate: true
    },
    {
      label: "BL Number",
      APILabel: "bl_Number",
      isDate: false
    },
    {
      label: "Container Type",
      APILabel: "container_Type",
      isDate: false
    },
    {
      label: "Vehicle Number",
      APILabel: "vehicle_Number",
      isDate: false
    },
    {
      label: "Shipping Line Transporter",
      APILabel: "shippingLine_Transporter",
      isDate: false
    },
    {
      label: "Container Number",
      APILabel: "container_Number",
      isDate: false
    },
    {
      label: "Shipping Line Name",
      APILabel: "shippingLine_Name",
      isDate: false
    },
    {
      label: "Driver Contact No",
      APILabel: "driver_Contact_No",
      isDate: false
    },
    {
      label: "Estimated Plan Date",
      APILabel: "estimated_Plan_Date",
      isDate: true
    },
    {
      label: "Transit Days",
      APILabel: "transit_Days",
      isDate: false
    },
    {
      label: "ETA Port Date",
      APILabel: "eta_Port_Date",
      isDate: true
    },
    {
      label: "ETA Factory Date",
      APILabel: "eta_Factory_Date",
      isDate: true
    },
    {
      label: "Payment Term",
      APILabel: "paymentTerm",
      isDate: false
    },
    {
      label: "Contigency Days",
      APILabel: "contigency_Days",
      isDate: false
    },
    {
      label: "Document Reminder Days",
      APILabel: "document_Reminder_Days",
      isDate: false
    },
    {
      label: "Document Uploaded Status",
      APILabel: "document_Uploaded_Status",
      isDate: false
    },
    {
      label: "Courier Pickup Company Name",
      APILabel: "courier_Pickup_Company_Name",
      isDate: false
    },
    {
      label: "Courier Pickup Docket Number",
      APILabel: "courier_Pickup_Docket_Number",
      isDate: false
    },
    {
      label: "Courier Pickup Company Website",
      APILabel: "courier_Pickup_Company_Website",
      isDate: false
    },
    {
      label: "Courier Delivery Company Name",
      APILabel: "courier_Delivery_Company_Name",
      isDate: false
    },
    {
      label: "Courier Delivery Docket Number",
      APILabel: "courier_Delivery_Docket_Number",
      isDate: false
    },
    {
      label: "Courier Delivery Company Website",
      APILabel: "courier_Delivery_Company_Website",
      isDate: false
    },
    {
      label: "Schedule Number",
      APILabel: "schedule_Number",
      isDate: false
    }
  ];
  let PoObjData = PoData.find((x) => x.APILabel === key) || {};

  return PoObjData;
}

// // Material Tab details Data
export function TabMaterialDetailsData(key) {
  const MaterialData = [
    {
      label: "Material Name",
      APILabel: "material_Name",
      isDate: false
    },
    {
      label: "Material SAP Code",
      APILabel: "material_SAP_Code",
      isDate: false
    },
    {
      label: "Customer PO Number",
      APILabel: "customer_PO_Number",
      isDate: false
    },
    {
      label: "Customer PO Date",
      APILabel: "customer_PO_Date",
      isDate: true
    },
    {
      label: "Knack Schedule SO Date",
      APILabel: "knack_Schedule_SO_Date",
      isDate: true
    },
    {
      label: "CO Customer Organization Name",
      APILabel: "cO_Customer_OrganizationName",
      isDate: false
    },
    {
      label: "Sale Unit",
      APILabel: "sale_Unit",
      isDate: false
    },
    {
      label: "Sale Quantity",
      APILabel: "sale_Quantity",
      isDate: false
    },
    {
      label: "Mode Of Tansport",
      APILabel: "mode_Of_Tansport",
      isDate: false
    },
    {
      label: "Invoice Number",
      APILabel: "invoice_Number",
      isDate: false
    },
    {
      label: "Invoice Date",
      APILabel: "invoice_Date",
      isDate: true
    },
    {
      label: "Container Number",
      APILabel: "container_Number",
      isDate: false
    },
    {
      label: "Vehicle Number",
      APILabel: "vehicle_Number",
      isDate: false
    },
    {
      label: "BL Date",
      APILabel: "bl_Date",
      isDate: true
    },
    {
      label: "BL Number",
      APILabel: "bl_Number",
      isDate: false
    },
    {
      label: "Estimated Plan Date",
      APILabel: "estimated_Plan_Date",
      isDate: true
    },
    {
      label: "Schedule Number",
      APILabel: "schedule_Number",
      isDate: false
    },
    {
      label: "Order Status Description",
      APILabel: "order_Status_Description",
      isDate: false
    }
  ];
  let MaterialObjData = MaterialData.find((x) => x.APILabel === key) || {};
  return MaterialObjData;
}
// //Invoice Tab details Data
export function TabInvoiceDetailsData(key) {
  const InvoiceData = [
    {
      label: "Invoice Number",
      APILabel: "invoice_Number",
      isDate: false
    },
    {
      label: "Material Id",
      APILabel: "material_Id",
      isDate: false
    },
    {
      label: "Order Schedule Id",
      APILabel: "order_Schedule_Id",
      isDate: false
    },
    {
      label: "Customer PO Number",
      APILabel: "customer_PO_Number",
      isDate: false
    },
    {
      label: "Customer PO Date",
      APILabel: "customer_PO_Date",
      isDate: true
    },
    {
      label: "SAP Order No",
      APILabel: "sap_Order_Number",
      isDate: false
    },
    {
      label: "Knack Schedule SO Date",
      APILabel: "knack_Schedule_SO_Date",
      isDate: true
    },
    {
      label: "Material Name",
      APILabel: "material_Name",
      isDate: false
    },
    {
      label: "Material SAP Code",
      APILabel: "material_SAP_Code",
      isDate: false
    },
    {
      label: "Sale Unit",
      APILabel: "sale_Unit",
      isDate: false
    },
    {
      label: "Invoice Date",
      APILabel: "invoice_Date",
      isDate: true
    },
    {
      label: "Sale Quantity",
      APILabel: "sale_Quantity",
      isDate: false
    },
    {
      label: "Mode Of Tansport",
      APILabel: "mode_Of_Tansport",
      isDate: false
    },
    {
      label: "Proposed Shipment Booking Date",
      APILabel: "proposed_Shipment_Booking_Date",
      isDate: true
    },
    {
      label: "Order Status Description",
      APILabel: "order_Status_Description",
      isDate: false
    },
    {
      label: "Till Date Dispatched Qty",
      APILabel: "till_Date_Dispatched_Qty",
      isDate: false
    },
    {
      label: "Balance Quantity",
      APILabel: "balance_Quantity",
      isDate: false
    },
    {
      label: "BL Date",
      APILabel: "bl_Date",
      isDate: true
    },
    {
      label: "BL Number",
      APILabel: "bl_Number",
      isDate: false
    },
    {
      label: "Container Type",
      APILabel: "container_Type",
      isDate: false
    },
    {
      label: "Vehicle Number",
      APILabel: "vehicle_Number",
      isDate: false
    },
    {
      label: "ShippingLine Transporter",
      APILabel: "shippingLine_Transporter",
      isDate: false
    },
    {
      label: "Container Number",
      APILabel: "container_Number",
      isDate: false
    },
    {
      label: "ShippingLine Name",
      APILabel: "shippingLine_Name",
      isDate: false
    },
    {
      label: "Driver Contact No",
      APILabel: "driver_Contact_No",
      isDate: false
    },
    {
      label: "Estimated Plan Date",
      APILabel: "estimated_Plan_Date",
      isDate: true
    },
    {
      label: "Transit Days",
      APILabel: "transit_Days",
      isDate: false
    },
    {
      label: "ETA Port Date",
      APILabel: "eta_Port_Date",
      isDate: true
    },
    {
      label: "ETA Factory Date",
      APILabel: "eta_Factory_Date",
      isDate: true
    },
    {
      label: "Payment Term",
      APILabel: "paymentTerm",
      isDate: false
    },
    {
      label: "Contigency Days",
      APILabel: "contigency_Days",
      isDate: false
    },
    {
      label: "Document Reminder Days",
      APILabel: "document_Reminder_Days",
      isDate: false
    },
    {
      label: "Document Uploaded Status",
      APILabel: "document_Uploaded_Status",
      isDate: false
    },
    {
      label: "Courier Pickup Company Name",
      APILabel: "courier_Pickup_Company_Name",
      isDate: false
    },
    {
      label: "Courier Pickup Docket Number",
      APILabel: "courier_Pickup_Docket_Number",
      isDate: false
    },
    {
      label: "Courier Pickup Company Website",
      APILabel: "courier_Pickup_Company_Website",
      isDate: false
    },
    {
      label: "Courier Delivery Company Name",
      APILabel: "courier_Delivery_Company_Name",
      isDate: false
    },
    {
      label: "Courier Delivery Docket Number",
      APILabel: "courier_Delivery_Docket_Number",
      isDate: false
    },
    {
      label: "Courier Delivery Company Website",
      APILabel: "courier_Delivery_Company_Website",
      isDate: false
    },
    {
      label: "Schedule Number",
      APILabel: "schedule_Number",
      isDate: false
    }
  ];
  let InvoiceObjData = InvoiceData.find((x) => x.APILabel === key) || {};

  return InvoiceObjData;
}

// //Container/Truck Tab details Data
export function TabVehicleDetailsData(key) {
  const VehicleData = [
    {
      label: "Container Or Vehicle ID",
      APILabel: "container_Or_Vehicle_ID",
      isDate: false
    },
    {
      label: "Vehicle Number",
      APILabel: "vehicle_Number",
      isDate: false
    },
    {
      label: "Container Number",
      APILabel: "container_Number",
      isDate: false
    },
    {
      label: "Invoice Number",
      APILabel: "invoice_Number",
      isDate: false
    },
    {
      label: "Customer PO Number",
      APILabel: "customer_PO_Number",
      isDate: false
    },
    {
      label: "Customer PO Date",
      APILabel: "customer_PO_Date",
      isDate: true
    },
    {
      label: "SAP Order No",
      APILabel: "sap_Order_Number",
      isDate: false
    },
    {
      label: "Knack Schedule SO Date",
      APILabel: "knack_Schedule_SO_Date",
      isDate: true
    },
    {
      label: "Material Name",
      APILabel: "material_Name",
      isDate: false
    },
    {
      label: "Material SAP Code",
      APILabel: "material_SAP_Code",
      isDate: false
    },
    {
      label: "Sale Unit",
      APILabel: "sale_Unit",
      isDate: false
    },
    {
      label: "Invoice Date",
      APILabel: "invoice_Date",
      isDate: true
    },
    {
      label: "Sale Quantity",
      APILabel: "sale_Quantity",
      isDate: false
    },
    {
      label: "Mode Of Tansport",
      APILabel: "mode_Of_Tansport",
      isDate: false
    },
    {
      label: "Proposed Shipment Booking Date",
      APILabel: "proposed_Shipment_Booking_Date",
      isDate: true
    },
    {
      label: "Order Status Description",
      APILabel: "order_Status_Description",
      isDate: false
    },
    {
      label: "Till Date Dispatched Qty",
      APILabel: "till_Date_Dispatched_Qty",
      isDate: false
    },
    {
      label: "Balance Quantity",
      APILabel: "balance_Quantity",
      isDate: false
    },
    {
      label: "BL Date",
      APILabel: "bl_Date",
      isDate: true
    },
    {
      label: "BL Number",
      APILabel: "bl_Number",
      isDate: false
    },
    {
      label: "Container Type",
      APILabel: "container_Type",
      isDate: false
    },
    {
      label: "Shipping Line Transporter",
      APILabel: "shippingLine_Transporter",
      isDate: false
    },
    {
      label: "ShippingLine Name",
      APILabel: "shippingLine_Name",
      isDate: false
    },
    {
      label: "Driver Contact No",
      APILabel: "driver_Contact_No",
      isDate: false
    },
    {
      label: "Estimated Plan Date",
      APILabel: "estimated_Plan_Date",
      isDate: true
    },
    {
      label: "Transit Days",
      APILabel: "transit_Days",
      isDate: false
    },
    {
      label: "ETA Port Date",
      APILabel: "eta_Port_Date",
      isDate: true
    },
    {
      label: "ETA Factory Date",
      APILabel: "eta_Factory_Date",
      isDate: true
    },
    {
      label: "Payment Term",
      APILabel: "paymentTerm",
      isDate: false
    },
    {
      label: "Contigency Days",
      APILabel: "contigency_Days",
      isDate: false
    },
    {
      label: "Document Reminder Days",
      APILabel: "document_Reminder_Days",
      isDate: false
    },
    {
      label: "Document Uploaded Status",
      APILabel: "document_Uploaded_Status",
      isDate: false
    },
    {
      label: "Courier Pickup Company Name",
      APILabel: "courier_Pickup_Company_Name",
      isDate: false
    },
    {
      label: "Courier Pickup Docket Number",
      APILabel: "courier_Pickup_Docket_Number",
      isDate: false
    },
    {
      label: "Courier Pickup Company Website",
      APILabel: "courier_Pickup_Company_Website",
      isDate: false
    },
    {
      label: "Courier Delivery Company Name",
      APILabel: "courier_Delivery_Company_Name",
      isDate: false
    },
    {
      label: "Courier Delivery Docket Number",
      APILabel: "courier_Delivery_Docket_Number",
      isDate: false
    },
    {
      label: "Courier Delivery Company Website",
      APILabel: "courier_Delivery_Company_Website",
      isDate: false
    },
    {
      label: "Schedule Number",
      APILabel: "schedule_Number",
      isDate: false
    }
  ];
  let VehicleObjData = VehicleData.find((x) => x.APILabel === key) || {};

  return VehicleObjData;
}

// //Assumption ETA Tab details Data
export function TabAssumptionDetailsData(key) {
  const AssumptionData = [
    {
      label: "Estimated Plan Date",
      APILabel: "estimated_Plan_Date",
      isDate: true
    },
    {
      label: "Invoice Number",
      APILabel: "invoice_Number",
      isDate: false
    },
    {
      label: "Customer PO No",
      APILabel: "customer_PO_Number",
      isDate: false
    },
    {
      label: "Customer PO Date",
      APILabel: "customer_PO_Date",
      isDate: true
    },
    {
      label: "SAP Order No",
      APILabel: "sap_Order_Number",
      isDate: false
    },
    {
      label: "Created On",
      APILabel: "knack_Schedule_SO_Date",
      isDate: true
    },
    {
      label: "Mat Desc",
      APILabel: "material_Name",
      isDate: false
    },
    {
      label: "Material",
      APILabel: "material_SAP_Code",
      isDate: false
    },
    {
      label: "Sale Quantity",
      APILabel: "sale_Quantity",
      isDate: false
    },
    {
      label: "Sale Unit",
      APILabel: "sale_Unit",
      isDate: false
    },
    {
      label: "Mode Of Tansport",
      APILabel: "mode_Of_Tansport",
      isDate: false
    },
    {
      label: "Proposed Shipment Booking Date",
      APILabel: "proposed_Shipment_Booking_Date",
      isDate: true
    },
    {
      label: "Invoice Date",
      APILabel: "invoice_Date",
      isDate: true
    },
    {
      label: "Order Status Description",
      APILabel: "order_Status_Description",
      isDate: false
    },
    {
      label: "Vehicle Number",
      APILabel: "vehicle_Number",
      isDate: false
    },
    {
      label: "Till Date Dispatched Qty",
      APILabel: "till_Date_Dispatched_Qty",
      isDate: false
    },
    {
      label: "Balance Quantity",
      APILabel: "balance_Quantity",
      isDate: false
    },
    {
      label: "BL Date",
      APILabel: "bl_Date",
      isDate: true
    },
    {
      label: "BL Number",
      APILabel: "bl_Number",
      isDate: false
    },
    {
      label: "Container Type",
      APILabel: "container_Type",
      isDate: false
    },
    {
      label: "Shipping Line Transporter",
      APILabel: "shippingLine_Transporter",
      isDate: false
    },
    {
      label: "Shipping Line Name",
      APILabel: "shippingLine_Name",
      isDate: false
    },
    {
      label: "Driver Contact No",
      APILabel: "driver_Contact_No",
      isDate: false
    },
    {
      label: "Till Date Dis Qty",
      APILabel: "till_Date_Dispatched_Qty",
      isDate: false
    },
    {
      label: "Total Dis Qty",
      APILabel: "totalQuantity",
      isDate: false
    },
    {
      label: "Transit Days",
      APILabel: "transit_Days",
      isDate: false
    },
    {
      label: "ETA Port Date",
      APILabel: "eta_Port_Date",
      isDate: true
    },
    {
      label: "ETA Factory Date",
      APILabel: "eta_Factory_Date",
      isDate: true
    },
    {
      label: "Payment Term",
      APILabel: "paymentTerm",
      isDate: false
    },
    {
      label: "Contigency Days",
      APILabel: "contigency_Days",
      isDate: false
    },
    {
      label: "Document Reminder Days",
      APILabel: "document_Reminder_Days",
      isDate: false
    },
    {
      label: "Document Uploaded Status",
      APILabel: "document_Uploaded_Status",
      isDate: false
    },
    {
      label: "Courier Pickup Company Name",
      APILabel: "courier_Pickup_Company_Name",
      isDate: false
    },
    {
      label: "Courier Pickup Docket Number",
      APILabel: "courier_Pickup_Docket_Number",
      isDate: false
    },
    {
      label: "Courier Pickup Company Website",
      APILabel: "courier_Pickup_Company_Website",
      isDate: false
    },
    {
      label: "Courier Delivery Company Name",
      APILabel: "courier_Delivery_Company_Name",
      isDate: false
    },
    {
      label: "Courier Delivery Docket Number",
      APILabel: "courier_Delivery_Docket_Number",
      isDate: false
    },
    {
      label: "Courier Delivery Company Website",
      APILabel: "courier_Delivery_Company_Website",
      isDate: false
    },
    {
      label: "Schedule Number",
      APILabel: "schedule_Number",
      isDate: false
    }
  ];
  let AssumptionObjData = AssumptionData.find((x) => x.APILabel === key) || {};

  return AssumptionObjData;
}
