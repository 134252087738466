import { httpTokenCommon } from "../helper/http-common";

class EmployeeFilterService {
  empGeneralManagerAPI() {
    return httpTokenCommon.get("GlobalDDFilter/GetEmpManagerData");
  }
  empRegionalManagerAPI(ids) {
    return httpTokenCommon.post("GlobalDDFilter/GetEmpRegionalManager", ids);
  }
  empAreaManagerAPI(ids) {
    return httpTokenCommon.post("GlobalDDFilter/GetEmpAreaManager", ids);
  }
  empGroupNameAPI(ids) {
    return httpTokenCommon.post("GlobalDDFilter/GetEmpGroupName", ids);
  }
  empCompanyNameAPI(ids) {
    return httpTokenCommon.post("GlobalDDFilter/GetEmpCompany", ids);
  }

  /// Customer Filters API 
  custGroupNameAPI() {
    return httpTokenCommon.get("GlobalDDFilter/GetCustCustomerGroup");
  }
  custOrganisationByGroupIdAPI(ids) {
    return httpTokenCommon.post("GlobalDDFilter/GetCustOrganisationByCustomerGroupId", ids);
  }
  custUsersByOrganisationIdAPI() {
    return httpTokenCommon.get("GlobalDDFilter/GetCustByCustomerOrganisationId");
  }
}

const employeeFilterService = new EmployeeFilterService();
export default employeeFilterService;
