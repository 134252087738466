import React, { Fragment, useEffect, useState } from "react";
import { InfoCircleTwoTone } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "antd";
import moment from "moment";
import {
  setTableListData,
  setTableActionDetails,
} from "../../actions/tableListing/tableListDataAction";

const LogisticCustomerDelivery = ({
  tabTableData,
  isLoading,
  OnhandleTableSortColumn,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isO2WPage =
    window.location.pathname.split("/")[1] === "dashboard" ? true : false;

  const tableSorting = useSelector(
    (state) => state.TableSortingData.tableListData
  );

  const [totalRecords, setTotalRecords] = useState(0);
  const [pageNo, setPageNo] = useState(1);

  useEffect(() => {
    if (tabTableData.logisticsCustomerDeliveryPendingTab?.length > 0) {
      setTotalRecords(tabTableData.totalRecords);
      setPageNo(Number(tabTableData.currentPage));
      dispatch(
        setTableListData(tabTableData.logisticsCustomerDeliveryPendingTab)
      );
    } else {
      setTotalRecords(0);
      setPageNo(1);
    }
  }, [tabTableData.logisticsCustomerDeliveryPendingTab]);

  const handleTableAscDes = (pagination, filters, sorter) => {
    const sortOrder = sorter.order;
    const sortField = sorter.field;
    OnhandleTableSortColumn({
      sortColumn: sortField,
      orderBy:
        sortOrder === "ascend"
          ? "asc"
          : sortOrder === "descend"
          ? "desc"
          : "asc",
      currentPage: pagination.current,
    });
  };

  const handleOverviewDetailsPage = (rowData) => {
    dispatch(
      setTableActionDetails({
        labelId: rowData.customer_PO_Number,
        valueName: rowData.customer_PO_Number,
        SapOrderNumber: rowData.sap_Order_Number,
      })
    );
    navigate(
      `/${
        isO2WPage ? "o2w" : "logistic"
      }/overviewDetails/vehicle/${rowData.customer_PO_Number.replace(
        /[ \/]/g,
        ""
      )}`
    );
  };

  const tableColumns = [
    {
      title: "Customer Name",
      dataIndex: "customer_Organization_Name",
      sorter: true,
      showSorterTooltip: false,
      sortOrder:
        tableSorting.sortColumn === "customer_Organization_Name"
          ? tableSorting.orderBy === "asc"
            ? "ascend"
            : "descend"
          : null,
      width: 200,
      render: (_i, row) => {
        return (
          <div>
            {row.customer_Organization_Name
              ? row.customer_Organization_Name
              : "N/A"}
          </div>
        );
      },
    },
    {
      title: "Port Name",
      dataIndex: "portName",
      sorter: true,
      showSorterTooltip: false,
      sortOrder:
        tableSorting.sortColumn === "portName"
          ? tableSorting.orderBy === "asc"
            ? "ascend"
            : "descend"
          : null,
      width: 160,
      render: (_i, row) => {
        return <div>{row.portName ? row.portName : "N/A"}</div>;
      },
    },
    {
      title: "Order Date",
      dataIndex: "customer_PO_Date",
      sorter: true,
      showSorterTooltip: false,
      sortOrder:
        tableSorting.sortColumn === "customer_PO_Date"
          ? tableSorting.orderBy === "asc"
            ? "ascend"
            : "descend"
          : null,
      width: 160,
      render: (_i, row) => {
        return (
          <div>
            {row.customer_PO_Date
              ? moment(row.customer_PO_Date).format("DD-MM-YYYY")
              : "N/A"}
          </div>
        );
      },
    },
    {
      title: "SAP Order No.",
      dataIndex: "sap_Order_Number",
      sorter: true,
      showSorterTooltip: false,
      sortOrder:
        tableSorting.sortColumn === "sap_Order_Number"
          ? tableSorting.orderBy === "asc"
            ? "ascend"
            : "descend"
          : null,
      width: 150,
      render: (_i, row) => {
        return <div>{row.sap_Order_Number ? row.sap_Order_Number : "N/A"}</div>;
      },
    },
    {
      title: "PO Number",
      dataIndex: "customer_PO_Number",
      sorter: true,
      showSorterTooltip: false,
      width: 160,
      sortOrder:
        tableSorting.sortColumn === "customer_PO_Number"
          ? tableSorting.orderBy === "asc"
            ? "ascend"
            : "descend"
          : null,
      render: (_i, row) => {
        return (
          <div>
            <label>
              {row.customer_PO_Number ? row.customer_PO_Number : "N/A"}
            </label>
          </div>
        );
      },
    },
    {
      title: "Actual ETA Port",
      dataIndex: "etA_Port_Actual",
      sorter: true,
      showSorterTooltip: false,
      sortOrder:
        tableSorting.sortColumn === "etA_Port_Actual"
          ? tableSorting.orderBy === "asc"
            ? "ascend"
            : "descend"
          : null,
      width: 150,
      render: (_i, row) => {
        return (
          <div>
            {row.etA_Port_Actual
              ? moment(row.etA_Port_Actual).format("DD-MM-YYYY")
              : "N/A"}
          </div>
        );
      },
    },
    {
      title: "Estimated Delivery Date",
      dataIndex: "delivery_Date_Estimated",
      sorter: true,
      showSorterTooltip: false,
      width: 200,
      sortOrder:
        tableSorting.sortColumn === "delivery_Date_Estimated"
          ? tableSorting.orderBy === "asc"
            ? "ascend"
            : "descend"
          : null,
      render: (_i, row) => {
        return (
          <div>
            {row.delivery_Date_Estimated
              ? moment(row.delivery_Date_Estimated).format("DD-MM-YYYY")
              : "N/A"}
          </div>
        );
      },
    },
    {
      title: "Material Number",
      dataIndex: "material_SAP_Code",
      sorter: true,
      showSorterTooltip: false,
      width: 160,
      sortOrder:
        tableSorting.sortColumn === "material_SAP_Code"
          ? tableSorting.orderBy === "asc"
            ? "ascend"
            : "descend"
          : null,
      render: (_i, row) => {
        return (
          <div>
            <label>
              {row.material_SAP_Code ? row.material_SAP_Code : "N/A"}
            </label>
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 80,
      fixed: "right",
      render: (_i, row) => (
        <div
          className="tbl_info_details"
          onClick={() => handleOverviewDetailsPage(row)}
        >
          <InfoCircleTwoTone />
        </div>
      ),
    },
  ];

  return (
    <Fragment>
      <Table
        bordered
        scroll={{ x: 900 }}
        className="logistic_tables"
        dataSource={tabTableData.logisticsCustomerDeliveryPendingTab}
        loading={isLoading}
        onChange={handleTableAscDes}
        rowClassName={(record, index) =>
          index % 2 === 0 ? "even-row" : "odd-row"
        }
        pagination={{
          total: totalRecords,
          pageNo: pageNo,
          pageSize: 10,
          responsive: true,
          showSizeChanger: false,
          current: pageNo,
          onChange: (pageNum) => setPageNo(pageNum),
        }}
        columns={tableColumns}
        rowStyle={(record, index) => ({
          backgroundColor: index % 2 === 0 ? "red" : "#ffffff",
        })}
      />
    </Fragment>
  );
};

export default LogisticCustomerDelivery;
