import React, { Fragment, useState, useEffect } from "react";
import { Select, Tabs, Breadcrumb, Button, Spin, Skeleton } from "antd";
import { DoubleLeftOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import OverviewTabs from "./TabViewDetails/OverviewTab";
import TimelineTab from "./TabViewDetails/TimelineTab";
import { NotificationManager } from "react-notifications";
import overTimeScheduleService from "../services/OverTimeSchedule-tabs-service";
import { imgTopArrwo } from "../pages/Elite/imageAssets";
import { useSelector } from "react-redux";

const { TabPane } = Tabs;
const { Option } = Select;

const TabViewDetails = ({ detailsTab, type, id }) => {
  const navigate = useNavigate();
  const tableDatalist = useSelector(
    (state) => state.TableListData.tableListData
  );
  const TableDetails = useSelector((state) => state.TableListData.tableDetails);
  const isO2WPage =
    window.location.pathname.split("/")[1] === "o2w" ? true : false;

  const [activeTabs, setActiveTabs] = useState("");
  const [quantityCountsDetails, setQuantityCountsDetails] = useState({});
  const [isScrollVisible, setIsScrollVisible] = useState(false);
  const [poMaterilaDropdownData, setPoMaterilaDropdownData] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (detailsTab === "overviewDetails") {
      setActiveTabs("1");
    } else {
      setActiveTabs("2");
    }
  }, [detailsTab]);

  useEffect(() => {
    getQuantityCountsData();
  }, []);

  useEffect(() => {
    getDropDownDataData();
  }, []);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsScrollVisible(true);
      } else {
        setIsScrollVisible(false);
      }
    };
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const getQuantityCountsData = async () => {
    setIsLoading(true);
    let inputData = {
      dropdownValue: selectedValue === "all" ? "" : selectedValue,
      labelValue: TableDetails.labelId,
      flag: type,
      SapOrderNumber: TableDetails.SapOrderNumber,
    };
    await overTimeScheduleService
      .getQuantityCountsDataAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finalData = response.data.responseData;
          if (finalData.length > 0) {
            setQuantityCountsDetails(finalData[0]);
          } else {
            setQuantityCountsDetails({});
          }
        } else {
          setQuantityCountsDetails({});
          NotificationManager.error(response.data.message);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error, "Error");
      });
  };

  const getDropDownDataData = async () => {
    setIsLoading(true);
    let inputData = {
      id:
        type === "material"
          ? tableDatalist.find((x) => x.material_id === TableDetails.labelId)
              .material_id
          : TableDetails.labelId,
      dropdownflag: type,
      SapOrderNumber: TableDetails.SapOrderNumber,
    };
    await overTimeScheduleService
      .getMaterialDropDownDataAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finalData = response.data.responseData;
          if (finalData.length > 0) {
            setPoMaterilaDropdownData(finalData);
            if (type !== "po" && type !== "material") {
              setSelectedValue(finalData[0].value);
            }
          } else {
            setSelectedValue(TableDetails.valueName);
          }
          setIsLoading(false);
        } else {
          setIsLoading(false);
          NotificationManager.error(response.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error, "Error");
      });
  };

  const handleTabChange = (key) => {
    setActiveTabs(key);
    if (key === "1") {
      navigate(
        `/${isO2WPage ? "o2w" : "lostigic"}/overviewDetails/${type}/${id}`
      );
    } else {
      navigate(
        `/${isO2WPage ? "o2w" : "lostigic"}/timelineDetails/${type}/${id}`
      );
    }
  };

  const handleSelectonChange = (value) => {
    setSelectedValue(value);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <Fragment>
      <div className="dashboard app-content content">
        <div className="content-wrapper">
          <div className="content-body">
            <div className="details_view_title mb-15">
              <Breadcrumb className="details_breadcrum" separator=">">
                <Breadcrumb.Item>
                  <Link to={`/dashboard/${type}`}>Dashboard</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <label>{type === "po" ? "PO" : type}</label>
                </Breadcrumb.Item>
              </Breadcrumb>
              <Link to={`/dashboard/${type}`}>
                <Button type="primary back_btn" icon={<DoubleLeftOutlined />}>
                  Back
                </Button>
              </Link>
            </div>
            <Spin size="large" spinning={isLoading}>
              <div>
                <div className="col-md-12 custom-dropdown d-flex">
                  <label className="col-md-3 custom-label pr-10 mb-4 pt-1">
                    {type === "material" ? "Material " : "PO "}:
                    {TableDetails.valueName}
                  </label>
                  <div className="select-container col-md-9">
                    <Select
                      value={
                        selectedValue
                          ? selectedValue
                          : `Please Select ${type === "po" ? "Material" : "PO"}`
                      }
                      onChange={handleSelectonChange}
                      disabled={
                        poMaterilaDropdownData.length === 0 ? true : false
                      }
                    >
                      {type === "po" || type === "material" ? (
                        <Option value="all">All</Option>
                      ) : null}
                      {poMaterilaDropdownData &&
                        poMaterilaDropdownData?.map((option, index) => (
                          <Option key={index} value={option.value}>
                            {option.name}
                          </Option>
                        ))}
                    </Select>
                  </div>
                </div>
              </div>
              {isLoading ? (
                <Skeleton.Button
                  size={"large"}
                  shape={"large"}
                  block={true}
                  active={isLoading}
                />
              ) : (
                <div
                  className={
                    type === "po" || type === "material" ? "row" : "row mr_top"
                  }
                >
                  <div className="col-md-12 d-flex mb-4">
                    <div className="col-xs-4 pr-20">
                      <label className="material_label" title="Ordered Qty">
                        Ordered Qty:
                      </label>
                      <span className="bg-primary text-white p-1 rounded material_count">
                        {quantityCountsDetails.total_Ordered ||
                        quantityCountsDetails.sale_Unit
                          ? quantityCountsDetails.total_Ordered +
                            " " +
                            quantityCountsDetails.sale_Unit
                          : "00"}
                      </span>
                    </div>
                    <div className="col-xs-4 pr-20">
                      <label className="material_label" title="Delivered Qty">
                        Delivered Qty:
                      </label>
                      <span className="bg-success text-white p-1 rounded material_count">
                        {quantityCountsDetails.total_Completed ||
                        quantityCountsDetails.sale_Unit
                          ? quantityCountsDetails.total_Completed +
                            " " +
                            quantityCountsDetails.sale_Unit
                          : "00"}
                      </span>
                    </div>
                    <div className="col-xs-4 pr-20">
                      <label className="material_label" title="Pending Qty">
                        Pending Qty:
                      </label>
                      <span className="bg-dark text-white p-1 rounded material_count">
                        {quantityCountsDetails.total_Pending ||
                        quantityCountsDetails.sale_Unit
                          ? quantityCountsDetails.total_Pending +
                            " " +
                            quantityCountsDetails.sale_Unit
                          : "00"}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </Spin>
            <div className="row dashboard_tabs material_tabs">
              <Tabs
                activeKey={activeTabs}
                type="card"
                onChange={handleTabChange}
              >
                <TabPane tab="Overview Tab" key="1">
                  <OverviewTabs
                    name={TableDetails.valueName}
                    labelId={TableDetails.labelId}
                    tabType={type}
                    dropdownValue={selectedValue}
                    SapOrderNumber={TableDetails.SapOrderNumber}
                  />
                </TabPane>
                <TabPane tab="Timeline Tab" key="2">
                  <TimelineTab
                    labelId={TableDetails.labelId}
                    tabType={type}
                    dropdownValue={selectedValue}
                    SapOrderNumber={TableDetails.SapOrderNumber}
                  />
                </TabPane>
                {/* <TabPane tab="Schedule Details" key="3"></TabPane> */}
              </Tabs>
            </div>
          </div>
          <div className="scroll-to-top">
            {isScrollVisible && (
              <div onClick={scrollToTop}>
                <img src={imgTopArrwo} alt="img" />
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TabViewDetails;
