import { combineReducers } from "redux";
import loginReducer from "./login/login.reducers";
import TableListData from "./TableListData/tableListData.reducer";
import CompanyDetails from "./CompanyDetails/company.reducer";
import TableSortingData from "./TableListData/tableSortingData.reducer";
import PaymentTableFilter from "./paymentTableFilter/paymentTableFilter.reducer";

export default combineReducers({
  loginReducer,
  TableListData,
  CompanyDetails,
  TableSortingData,
  PaymentTableFilter,
});
