import { httpTokenCommon } from "../../helper/http-common";

class LogisticDashboardService {
  getLogisticTileCountsAPI(inputData) {
    return httpTokenCommon.post("Logistic/GetLogisticCount", inputData);
  }

  getLogisticDashboardTableListingAPI(inputData) {
    return httpTokenCommon.post("Logistic/GetLogisticsTabsData", inputData);
  }
}

const logisticDashboardService = new LogisticDashboardService();
export default logisticDashboardService;
