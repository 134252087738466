import {
  SET_COMPANYDETAILS,
  SET_COMPANYNULL
} from "../../actions/companyDetails/CompanyCodeTypes";

const initialState = {
  companyDetials: null
};

function companyReducer(companyState = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_COMPANYDETAILS:
      return { ...companyState, companyDetials: payload };
    case SET_COMPANYNULL:
      return { ...companyState, companyDetials: undefined };
    default:
      return companyState;
  }
}

export default companyReducer;
