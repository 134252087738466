import React, { Fragment, useState, useEffect } from "react";
import { Button, Card, Table, DatePicker, Typography } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import GlobalFilterDrawer from "../../../components/GlobalFilterDrawer";
import { FilePdfOutlined } from "@ant-design/icons";
import { editIcon, deleteIcon } from "../imageAssets";
import { icons } from "antd/lib/image/PreviewGroup";
const SecurityReport = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  //const [isEmployee, setIsEmployee] = useState(false);
  const [isGlobalFilter, setIsGlobalFilter] = useState(false);
  const [applyFilterObj, setApplyFilterObj] = useState({});
  const handleDateChange = (date, dateString) => {
    console.log("Selected Date:", dateString);
  };

  const handleSubmit = () => {
    console.log("Submit button clicked");
  };

  const handleExportPDF = () => {
    console.log("Export PDF clicked");
  };
  const handleApplyFilter = (objData) => {
    setApplyFilterObj(objData);
  };
  const showFilterDrawer = () => {
    setDrawerOpen((prevDrawerOpen) => !prevDrawerOpen);
    setIsGlobalFilter(!isGlobalFilter);
  };
  const customerAgingData = {
    customerAgeingList: [
      {
        Id: "1",
        People: "3",
        Date: "10/03/2024",
        InTime: "10:15 AM",
        OutTime: "10:15 AM",
        Lunch: "Yes",
        Transport: "Self Car",
        CarNumber: "GJ-27-CF-5424",
        VehicleName: "Baleno",
        Remarks: "There are many variations of",
      },
      {
        Id: "2",
        People: "7",
        Date: "10/03/2024",
        InTime: "12:15 PM",
        OutTime: "12:15 PM",
        Lunch: "No",
        Transport: "Company Car",
        CarNumber: "GJ-1-CF-7129",
        VehicleName: "Innova",
        Remarks: "Lorem ipsum generators",
      },
      {
        Id: "3",
        People: "15",
        Date: "10/03/2024",
        InTime: "03:15 PM",
        OutTime: "03:15 PM",
        Lunch: "Yes",
        Transport: "Self Car",
        CarNumber: "GJ-27-CF-5424",
        VehicleName: "Baleno",
        Remarks: "Lorem ipsum generators",
      },
      {
        Id: "4",
        People: "9",
        Date: "10/03/2024",
        InTime: "10:15 AM",
        OutTime: "10:15 AM",
        Lunch: "No",
        Transport: "Self Car",
        CarNumber: "GJ-1-CF-7129",
        VehicleName: "Swift Dzire",
        Remarks: "There are many variations",
      },
      {
        Id: "5",
        People: "6",
        Date: "10/03/2024",
        InTime: "12:20 PM",
        OutTime: "12:20 PM",
        Lunch: "Yes",
        Transport: "Company Car",
        CarNumber: "GJ-27-CF-5424",
        VehicleName: "Innova",
        Remarks: "Professor at Hampden",
      },
      {
        Id: "6",
        People: "8",
        Date: "10/03/2024",
        InTime: "10:15 AM",
        OutTime: "10:15 AM",
        Lunch: "Yes",
        Transport: "Self Car",
        CarNumber: "GJ-27-CF-5424",
        VehicleName: "Baleno",
        Remarks: "There are many variations of",
      },
      {
        Id: "7",
        People: "11",
        Date: "10/03/2024",
        InTime: "12:15 PM",
        OutTime: "12:15 PM",
        Lunch: "No",
        Transport: "Company Car",
        CarNumber: "GJ-1-CF-7129",
        VehicleName: "Innova",
        Remarks: "Lorem ipsum generators",
      },
      {
        Id: "8",
        People: "18",
        Date: "10/03/2024",
        InTime: "03:15 PM",
        OutTime: "03:15 PM",
        Lunch: "Yes",
        Transport: "Self Car",
        CarNumber: "GJ-27-CF-5424",
        VehicleName: "Baleno",
        Remarks: "Lorem ipsum generators",
      },
      {
        Id: "9",
        People: "4",
        Date: "10/03/2024",
        InTime: "10:15 AM",
        OutTime: "10:15 AM",
        Lunch: "No",
        Transport: "Self Car",
        CarNumber: "GJ-1-CF-7129",
        VehicleName: "Swift Dzire",
        Remarks: "There are many variations",
      },
      {
        Id: "10",
        People: "20",
        Date: "10/03/2024",
        InTime: "12:20 PM",
        OutTime: "12:20 PM",
        Lunch: "Yes",
        Transport: "Company Car",
        CarNumber: "GJ-27-CF-5424",
        VehicleName: "Innova",
        Remarks: "Professor at Hampden",
      },
      {
        Id: "11",
        People: "12",
        Date: "10/03/2024",
        InTime: "10:15 AM",
        OutTime: "10:15 AM",
        Lunch: "Yes",
        Transport: "Self Car",
        CarNumber: "GJ-27-CF-5424",
        VehicleName: "Baleno",
        Remarks: "There are many variations of",
      },
      {
        Id: "12",
        People: "14",
        Date: "10/03/2024",
        InTime: "12:15 PM",
        OutTime: "12:15 PM",
        Lunch: "No",
        Transport: "Company Car",
        CarNumber: "GJ-1-CF-7129",
        VehicleName: "Innova",
        Remarks: "Lorem ipsum generators",
      },
      {
        Id: "13",
        People: "9",
        Date: "10/03/2024",
        InTime: "03:15 PM",
        OutTime: "03:15 PM",
        Lunch: "Yes",
        Transport: "Self Car",
        CarNumber: "GJ-27-CF-5424",
        VehicleName: "Baleno",
        Remarks: "Lorem ipsum generators",
      },
      {
        Id: "14",
        People: "10",
        Date: "10/03/2024",
        InTime: "10:15 AM",
        OutTime: "10:15 AM",
        Lunch: "No",
        Transport: "Self Car",
        CarNumber: "GJ-1-CF-7129",
        VehicleName: "Swift Dzire",
        Remarks: "There are many variations",
      },
      {
        Id: "15",
        People: "17",
        Date: "10/03/2024",
        InTime: "12:20 PM",
        OutTime: "12:20 PM",
        Lunch: "Yes",
        Transport: "Company Car",
        CarNumber: "GJ-27-CF-5424",
        VehicleName: "Innova",
        Remarks: "Professor at Hampden",
      },
    ],
  };

  const tableColumns = [
    {
      title: "Sr No",
      dataIndex: "Id",
      sorter: (a, b) => a.customerOrganization - b.customerOrganization,
      showSorterTooltip: false,
      width: 100,
      render: (_text, _record, index) => (
        <input type="checkbox" id={`checkbox-${index}`} />
      ),
    },
    {
      title: "People",
      dataIndex: "People",
      sorter: (a, b) =>
        parseFloat(a.totalPendingAmount_LCY.replace(/,/g, "")) -
        parseFloat(b.totalPendingAmount_LCY.replace(/,/g, "")),
      showSorterTooltip: false,
      width: 100,
    },
    {
      title: "Date",
      dataIndex: "Date",
      sorter: (a, b) =>
        parseFloat(a["second"].replace(/,/g, "")) -
        parseFloat(b["second"].replace(/,/g, "")),
      showSorterTooltip: false,
      width: 180,
      // render: (_i, row) => {
      //   return (
      //     <div>
      //       {row.second !== ""
      //         ? formatNumber(parseFloat(row.second.replace(/,/g, "")))
      //         : "-"}
      //     </div>
      //   );
      // },
    },
    {
      title: "In Time",
      dataIndex: "InTime",
      sorter: (a, b) =>
        parseFloat(a["third"].replace(/,/g, "")) -
        parseFloat(b["third"].replace(/,/g, "")),
      showSorterTooltip: false,
      width: 120,
      // render: (_i, row) => {
      //   return (
      //     <div>
      //       {row.third !== ""
      //         ? formatNumber(parseFloat(row.third.replace(/,/g, "")))
      //         : "-"}
      //     </div>
      //   );
      // },
    },
    {
      title: "Out Time",
      dataIndex: "OutTime",
      sorter: (a, b) =>
        parseFloat(a["fourth"].replace(/,/g, "")) -
        parseFloat(b["fourth"].replace(/,/g, "")),
      showSorterTooltip: false,
      width: 120,
      // render: (_i, row) => {
      //   return (
      //     <div>
      //       {row.fourth !== ""
      //         ? formatNumber(parseFloat(row.fourth.replace(/,/g, "")))
      //         : "-"}
      //     </div>
      //   );
      // },
    },
    {
      title: "Lunch",
      dataIndex: "Lunch",
      sorter: (a, b) =>
        parseFloat(a["fifth"].replace(/,/g, "")) -
        parseFloat(b["fifth"].replace(/,/g, "")),
      showSorterTooltip: false,
      width: 120,
      // render: (_i, row) => {
      //   return (
      //     <div>
      //       {row.fifth !== ""
      //         ? formatNumber(parseFloat(row.fifth.replace(/,/g, "")))
      //         : "-"}
      //     </div>
      //   );
      // },
    },
    {
      title: "Transport",
      dataIndex: "Transport",
      sorter: (a, b) =>
        parseFloat(a["sixth"].replace(/,/g, "")) -
        parseFloat(b["sixth"].replace(/,/g, "")),
      showSorterTooltip: false,
      width: 120,
      // render: (_i, row) => {
      //   return (
      //     <div>
      //       {row.sixth !== ""
      //         ? formatNumber(parseFloat(row.sixth.replace(/,/g, "")))
      //         : "-"}
      //     </div>
      //   );
      // },
    },
    {
      title: "Car Number",
      dataIndex: "CarNumber",
      sorter: (a, b) =>
        parseFloat(a["seventh"].replace(/,/g, "")) -
        parseFloat(b["seventh"].replace(/,/g, "")),
      showSorterTooltip: false,
      width: 180,
      // render: (_i, row) => {
      //   return (
      //     <div>
      //       {row.seventh !== ""
      //         ? formatNumber(parseFloat(row.seventh.replace(/,/g, "")))
      //         : "-"}
      //     </div>
      //   );
      // },
    },
    {
      title: "Vehicle Name",
      dataIndex: "VehicleName",
      sorter: (a, b) =>
        parseFloat(a["eighth"].replace(/,/g, "")) -
        parseFloat(b["eighth"].replace(/,/g, "")),
      showSorterTooltip: false,
      width: 180,
    },
    {
      title: "Remark",
      dataIndex: "Remarks",
      sorter: (a, b) =>
        parseFloat(a["eighth"].replace(/,/g, "")) -
        parseFloat(b["eighth"].replace(/,/g, "")),
      showSorterTooltip: false,
      width: 200,
    },
    {
      title: "Action",
      dataIndex: "action",
      fixed: "right",
      width: 120,
      render: (_text, _record, index) => (
        <div className="dis_flex">
          <div>
            {" "}
            <img src={editIcon} alt="img" /> |
          </div>
          <div>
            {" "}
            <img src={deleteIcon} alt="img" />
          </div>
        </div>
      ),
    },
  ];
  useEffect(() => {
    if (Object.keys(applyFilterObj).length > 0) {
    }
  }, [applyFilterObj]);

  return (
    <Fragment>
      <div className="app-content content paymentModule">
        <div className="content-wrapper">
          <div className="content-body">
            <h5 className="content-header-title d-inline-block">
              Security Report
            </h5>
            <div className="main-setting">
              <Button
                className={`setting-button ${drawerOpen ? "open" : ""}`}
                icon={<SettingOutlined spin />}
                onClick={() => showFilterDrawer()}
              />
            </div>
            <div className="pay_section_3">
              <div className="row payment_tab">
                <div className="dis_flex">
                  <label style={{ fontWeight: "bold", marginRight: "8px" }}>
                    Date of visit
                  </label>
                  <DatePicker
                    onChange={handleDateChange}
                    placeholder="Select Date"
                  />
                  <Button type="primary" onClick={handleSubmit}>
                    Submit
                  </Button>
                  <Typography.Link
                    onClick={handleExportPDF}
                    style={{ marginLeft: "auto", color: "#1890ff" }}
                  >
                    Export PDF <FilePdfOutlined />
                  </Typography.Link>
                </div>
              </div>
            </div>
            <Card className="">
              <div className="row">
                <div className="col-md-12">
                  <Table
                    bordered
                    scroll={{ x: 900 }}
                    className="dashboard_tables payment_table"
                    dataSource={customerAgingData.customerAgeingList}
                    // loading={customerAgingLoading}
                    // onChange={handleTableAscDes}
                    // pagination={{
                    //   total: totalRecords,
                    //   pageNo: pageNo,
                    //   pageSize: 10,
                    //   responsive: true,
                    //   showSizeChanger: false,
                    //   current: pageNo,
                    //   onChange: (pageNum) => setPageNo(pageNum),
                    // }}
                    columns={tableColumns}
                  />
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
      <div className="main-filter">
        <GlobalFilterDrawer
          isGlobalFilter={isGlobalFilter}
          showFilterDrawer={showFilterDrawer}
          //isEmployee={isEmployee}
          applyFilter={handleApplyFilter}
        />
      </div>
    </Fragment>
  );
};

export default SecurityReport;
