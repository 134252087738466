import { httpCommon } from "../helper/http-common";

class LoginService {
  login(InputData) {
    return httpCommon.post("Login/Login", InputData);
  }
}

const loginService = new LoginService();
export default loginService;
