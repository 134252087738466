import {
  SET_TABLELISTDATA,
  SET_TABLEACTIONDETAILS
} from "../../actions/tableListing/tableListDataTypes";

const initialState = {
  tableListData: null,
  tableDetails: {
    labelId: "",
    valueName: ""
  }
};

function TableListData(tableState = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_TABLELISTDATA:
      return { ...tableState, tableListData: payload };
    case SET_TABLEACTIONDETAILS:
      return { ...tableState, tableDetails: payload };
    default:
      return tableState;
  }
}

export default TableListData;
