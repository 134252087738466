export function checkTabTypeName(type) {
  let tabName = "";
  switch (type) {
    case "all":
      tabName = "All";
      break;
    case "po":
      tabName = "Purchase Order";
      break;
    case "material":
      tabName = "Material";
      break;
    case "invoice":
      tabName = "Invoice";
      break;
    case "vehicle":
      tabName = "Container/Truck";
      break;
    case "assumption":
      tabName = "Assumption ETA";
      break;

    default:
      tabName = "All";
      break;
  }
  return tabName;
}

export function checkO2WActiveTabNo(tab) {
  let tabName = "";
  switch (tab) {
    case "all":
      tabName = "1";
      break;
    case "po":
      tabName = "2";
      break;
    case "material":
      tabName = "3";
      break;
    case "invoice":
      tabName = "4";
      break;
    case "vehicle":
      tabName = "5";
      break;
    case "assumption":
      tabName = "6";
      break;

    default:
      tabName = "1";
      break;
  }
  return tabName;
}

export function checkLogisticActiveTabNo(tab) {
  let tabName = "";
  switch (tab) {
    case "all":
      tabName = "1";
      break;
    case "Pending Dispatch":
      tabName = "2";
      break;
    case "Pending BL":
      tabName = "3";
      break;
    case "Intransit":
      tabName = "4";
      break;
    case "Customer Delivery Pending":
      tabName = "5";
      break;
    case "Delivered":
      tabName = "6";
      break;

    default:
      tabName = "1";
      break;
  }
  return tabName;
}

export function checkPaymentActiveTabNo(tab) {
  let tabName = "";
  switch (tab) {
    case "customer":
      tabName = "1";
      break;
    case "customerOutstanding":
      tabName = "2";
      break;
    case "customerAging":
      tabName = "3";
      break;
    case "invoice":
      tabName = "4";
      break;
    default:
      tabName = "1";
      break;
  }
  return tabName;
}
