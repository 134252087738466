import React, { useEffect, useRef } from "react";
import {
	BrowserRouter,
	Route,
	Routes,
	Navigate,
	Outlet,
	useParams,
} from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import { useSelector } from "react-redux";

import O2WDashboard from "./pages/Elite/O2W/O2WDashboard";
import TabViewDetails from "../src/components/TabViewDetails";
import Login from "./pages/Login/Login";
import Layout from "./pages/main/layout";
import EliteCard from "./pages/Elite/EliteCard";
import EliteDashboard from "./pages/Elite/EliteDashboard";
import DetailsView from "./components/DetailsView";
import PaymentDashboard from "./pages/Elite/Payment/paymentDashboard";
import useDebouncedResizeObserver from "./hooks/useDebouncedResizeObserver";
import logisticDashboard from "./pages/Elite/Logistics/logisticDashboard";
import CreateGatePass from "./pages/Elite/VisitorManagement/createGatePass";
import createNewVisitor from "./pages/Elite/VisitorManagement/createNewVisitor";
import securityReport from "./pages/Elite/VisitorManagement/securityReport";
import receptionReport from "./pages/Elite/VisitorManagement/receptionReport";
import visitorReport from "./pages/Elite/VisitorManagement/visitorReport";

const PrivateRoute = () => {
	const loginDetails = useSelector((state) => state.loginReducer);
	const isLoginSucceeded =
		loginDetails?.loginDetials?.userDetails?.isLoginSucceeded || false;

	return isLoginSucceeded ? (
		<Layout>
			<Outlet />
		</Layout>
	) : (
		<Navigate to="/" />
	);
};

const RouteWithProps = ({ component: Component, ...rest }) => {
	const params = useParams();
	return <Component {...params} {...rest} />;
};
const ExternalRedirect = ({ url }) => {
	useEffect(() => {
		window.location.href = url;
	}, [url]);
	return null;
};

const App = () => {
	const appRef = useRef(null);

	const handleResize = (entries) => {};

	const { observe, unobserve } = useDebouncedResizeObserver(handleResize);
	useEffect(() => {
		const handleCloseDropdowns = (e) => {
			// Ensure e.target is an Element (to avoid errors)
			if (!(e.target instanceof Element)) {
				return;
			}

			if (
				e.target.closest(".ant-select-dropdown") ||
				e.target.closest(".ant-picker-dropdown")
			) {
				return;
			}

			const openSelects = document.querySelectorAll(
				".ant-select.ant-select-open"
			);
			openSelects.forEach((dropdown) => {
				const selectInput = dropdown.querySelector("input");
				if (selectInput) {
					selectInput.blur();
				}
			});

			const openDatePickers = document.querySelectorAll(
				".ant-picker.ant-picker-focused"
			);
			openDatePickers.forEach((datePicker) => {
				const inputElement = datePicker.querySelector("input");
				if (inputElement) {
					inputElement.blur();
				}
			});
		};

		window.addEventListener("scroll", handleCloseDropdowns);
		window.addEventListener("wheel", handleCloseDropdowns);

		return () => {
			window.removeEventListener("scroll", handleCloseDropdowns);
			window.removeEventListener("wheel", handleCloseDropdowns);
		};
	}, []);

	useEffect(() => {
		if (appRef.current) {
			observe(appRef.current);
		}
		return () => {
			if (appRef.current) {
				unobserve(appRef.current);
			}
		};
	}, [observe, unobserve]);

	useEffect(() => {
		const handleError = (e) => {
			if (e.message === "ResizeObserver loop limit exceeded") {
				const resizeObserverErrDiv = document.getElementById(
					"webpack-dev-server-client-overlay-div"
				);
				const resizeObserverErr = document.getElementById(
					"webpack-dev-server-client-overlay"
				);
				if (resizeObserverErr) {
					resizeObserverErr.setAttribute("style", "display: none");
				}
				if (resizeObserverErrDiv) {
					resizeObserverErrDiv.setAttribute("style", "display: none");
				}
			}
		};

		window.addEventListener("error", handleError);

		return () => {
			window.removeEventListener("error", handleError);
		};
	}, []);

	return (
		<div ref={appRef}>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Login />} />
					<Route element={<PrivateRoute />}>
						<Route path="/galaxyHome" element={<EliteCard />} />
						<Route path="/eliteDashboard" element={<EliteDashboard />} />
						<Route
							path="/dashboard/:type?"
							element={<RouteWithProps component={O2WDashboard} />}
						/>
						<Route
							path="/:module/:detailsTab/:type/:id?"
							element={<RouteWithProps component={TabViewDetails} />}
						/>
						<Route
							path="/detailsView/:type/:id?"
							element={<RouteWithProps component={DetailsView} />}
						/>
						<Route
							path="/payment/:type?"
							element={<RouteWithProps component={PaymentDashboard} />}
						/>
						<Route
							path="/logistic/:type?"
							element={<RouteWithProps component={logisticDashboard} />}
						/>
						{/* Visitor Management Routes */}
						<Route
							path="/createGatePass"
							element={<RouteWithProps component={CreateGatePass} />}
						/>
						<Route
							path="/createNewVisitor"
							element={<RouteWithProps component={createNewVisitor} />}
						/>
						<Route
							path="/receptionReport"
							element={<RouteWithProps component={receptionReport} />}
						/>
						<Route
							path="/securityReport"
							element={<RouteWithProps component={securityReport} />}
						/>
						<Route
							path="/visitorReport"
							element={<RouteWithProps component={visitorReport} />}
						/>
					</Route>
					<Route
						path="/admin"
						element={
							<ExternalRedirect url="https://knack-admin.brainvire.net/Login" />
						}
					/>
				</Routes>
				<NotificationContainer />
			</BrowserRouter>
		</div>
	);
};

export default App;
