import { useEffect, useRef, useCallback } from 'react';

const useDebouncedResizeObserver = (callback, delay = 100) => {
  const timeoutRef = useRef(null);

  const debouncedCallback = useCallback((entries) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      callback(entries);
    }, delay);
  }, [callback, delay]);

  const observer = useRef(
    new ResizeObserver((entries) => {
      debouncedCallback(entries);
    })
  );

  useEffect(() => {
    const currentObserver = observer.current;
    return () => {
      currentObserver.disconnect();
    };
  }, []);

  const observe = useCallback((element) => {
    if (element) {
      observer.current.observe(element);
    }
  }, []);

  const unobserve = useCallback((element) => {
    if (element) {
      observer.current.unobserve(element);
    }
  }, []);

  return { observe, unobserve };
};

export default useDebouncedResizeObserver;
