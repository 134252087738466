import React, { Fragment, useEffect, useState } from "react";
import { Input, Card } from "antd";
import CustomerBarChart from "./CustomerBarChart";
import { NotificationManager } from "react-notifications";
import CurrencyOutstandingTbl from "./CurrencyOutstandingTbl";
import paymentService from "../../../../services/Payment/payment-service";
import { useSelector, useDispatch } from "react-redux";
import { CloseOutlined } from "@ant-design/icons";
import { setPaymentTableFilter } from "../../../../actions/paymentTableFilter/paymentTableFilterAction";
import { PayloadForApplyFilterData } from "../../../../components/PayloadForApplyFilterData";
const { Search } = Input;

const CustomerTab = ({
  selectAnalysisType,
  selectedDistributionIds,
  selectedCountryIds,
  selectedPriceGroupIds,
  selectedSalesOfficeIds,
  SelectedBarChartData,
  isEmployee,
  applyFilterObj,
}) => {
  const dispatch = useDispatch();
  const companyDetails = useSelector(
    (state) => state.CompanyDetails.companyDetials
  );

  const selectedCustomerAgingData = useSelector(
    (state) => state.PaymentTableFilter.selectedRow
  );

  const [isTblLoading, setIsTblLoading] = useState(false);
  const [customerBarChartData, setCustomerBarChartData] = useState([]);
  const [currencyWiseDataTbl, setCurrencyWiseDataTbl] = useState([]);
  const [totalPendingLCY, setTotalPendingLCY] = useState(0);
  const [chartSearchtext, setChartSearchtext] = useState("");
  const [selectedChartCurrencyData, setSelectedChartCurrencyData] =
    useState("");
  const [selectedBarData, setSelectedBarData] = useState([]);

  useEffect(() => {
    const searchTime = setTimeout(() => {
      handleGetPaymentChartData();
      handleGetPaymentCurrencyTableData();
    }, 500);

    return () => {
      clearTimeout(searchTime);
    };
  }, [
    selectAnalysisType,
    selectedDistributionIds,
    selectedCountryIds,
    selectedPriceGroupIds,
    selectedSalesOfficeIds,
    applyFilterObj,
  ]);

  useEffect(() => {
    // if (chartSearchtext.trim() !== "") {
    handleGetPaymentChartData();
    handleGetPaymentCurrencyTableData();
    // }
  }, [chartSearchtext, selectedChartCurrencyData]);

  useEffect(() => {
    setChartSearchtext(selectedCustomerAgingData);
  }, [selectedCustomerAgingData]);

  useEffect(() => {
    SelectedBarChartData(selectedBarData);
    const tempCustomerIds = selectedBarData
      .map((item) => item.customerId)
      .join(",");
    setSelectedChartCurrencyData(tempCustomerIds);
  }, [selectedBarData]);

  ///// API calling function code start
  /// handle fetch payment chart data listing
  const handleGetPaymentChartData = () => {
    let inputData = {
      distributionChannel: selectedDistributionIds
        ? selectedDistributionIds.join(",")
        : "",
      country: selectedCountryIds.join(","),
      salesOffice: selectedSalesOfficeIds
        ? selectedSalesOfficeIds.join(",")
        : "",
      priceGroup: selectedPriceGroupIds ? selectedPriceGroupIds.join(",") : "",
      type: selectAnalysisType,
      search: chartSearchtext ? chartSearchtext.trim() : "",
      pageindex: "1",
      pagesize: "12",
      ...PayloadForApplyFilterData(applyFilterObj, companyDetails),
    };
    paymentService
      .getPaymentBarChartDataAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finaldata = response.data.responseData;
          if (finaldata.customerWiseList?.length > 0) {
            setCustomerBarChartData(finaldata.customerWiseList);
          } else {
            setCustomerBarChartData([]);
          }
        } else {
          NotificationManager.error(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };
  /// handle fetch payment currency Table data listing
  const handleGetPaymentCurrencyTableData = () => {
    setIsTblLoading(true);
    let inputData = {
      distributionChannel: selectedDistributionIds
        ? selectedDistributionIds.join(",")
        : "",
      country: selectedCountryIds.join(","),
      salesOffice: selectedSalesOfficeIds
        ? selectedSalesOfficeIds.join(",")
        : "",
      priceGroup: selectedPriceGroupIds ? selectedPriceGroupIds.join(",") : "",
      type: selectAnalysisType,
      search: chartSearchtext
        ? chartSearchtext.trim()
        : selectedChartCurrencyData
        ? selectedChartCurrencyData.trim()
        : "",
      ...PayloadForApplyFilterData(applyFilterObj, companyDetails),
    };
    paymentService
      .getPaymentCurrencyWiseDataAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finaldata = response.data.responseData;
          if (finaldata.totalPaymentListResponse?.length > 0) {
            setCurrencyWiseDataTbl(finaldata.totalPaymentListResponse);
            setTotalPendingLCY(finaldata.total);
          } else {
            setCurrencyWiseDataTbl([]);
            setTotalPendingLCY(0);
          }
        } else {
          NotificationManager.error(response.data.message);
        }
        setIsTblLoading(false);
      })
      .catch((error) => {
        setIsTblLoading(false);
        console.log(error, "Error");
      });
  };
  ///// API calling function code end
  const handleOnSearchData = (value) => {
    setChartSearchtext(value);
    dispatch(setPaymentTableFilter(""));
  };
  const handleChartSearchOnchange = (e) => {
    setChartSearchtext(e.target.value);
    dispatch(setPaymentTableFilter(""));
  };

  const handleBarSelectedData = (data) => {
    const customerIdToCheck = data.customerId;
    setSelectedBarData((prev) => {
      const exists = prev.some((item) => item.customerId === customerIdToCheck);
      if (!exists) {
        return [...prev, data];
      }
      return prev;
    });
  };

  const handleRemoveSelectedData = (customerId) => {
    const updatedData = selectedBarData.filter(
      (x) => x.customerId !== customerId
    );
    setSelectedBarData(updatedData);
    dispatch(setPaymentTableFilter(""));
  };

  return (
    <Fragment>
      <div className="pay_customer_tab">
        <div className="row">
          <div className="col-md-6 mb-10">
            <Card>
              <div className="cust_card">
                <div className="payment_customer_section">
                  <label className="card_lbl">Customers</label>
                  <div className="pay_search_int">
                    <Search
                      placeholder="Search..."
                      allowClear
                      className="dash_search"
                      size="large"
                      value={chartSearchtext}
                      onSearch={handleOnSearchData}
                      onChange={handleChartSearchOnchange}
                    />
                  </div>
                </div>
                <div className="tag-container mt-2">
                  {selectedBarData.map((item, i) => {
                    return (
                      <div className="chart_tag" key={i}>
                        <span className="tag-text">
                          {item.fullName.length > 8
                            ? item.fullName.substring(0, 8) + "..."
                            : item.fullName}
                        </span>
                        <CloseOutlined
                          className="close-btn"
                          onClick={() =>
                            handleRemoveSelectedData(item.customerId)
                          }
                        />
                      </div>
                    );
                  })}
                </div>
                <CustomerBarChart
                  BarChartData={customerBarChartData}
                  handleBarClick={handleBarSelectedData}
                />
              </div>
            </Card>
          </div>
          <div className="col-md-6">
            <Card>
              <div className="d-flex justify-content-between">
                <label className="card_lbl">Currency wise outstanding</label>
              </div>
              <div className="cust_out_tbl">
                <CurrencyOutstandingTbl
                  tableData={currencyWiseDataTbl}
                  totalLCY={totalPendingLCY}
                  isLoading={isTblLoading}
                  isEmployee={isEmployee}
                />
              </div>
            </Card>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CustomerTab;
