import {
  SET_TABLESORTINGDATA,
  SET_TABLESEARCHDATA
} from "./tableListDataTypes";

export const setTableSortingData = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_TABLESORTINGDATA,
      payload: data
    });
  } catch (err) {}
};

export const setTableSearchInput = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_TABLESEARCHDATA,
      payload: data
    });
  } catch (err) {}
};
