import { httpTokenCommon } from "../helper/http-common";

class HeaderService {
  getKnackCompanyListAPI() {
    return httpTokenCommon.get("GlobalDDFilter/GetKnackCompanyList");
  }
}

const headerService = new HeaderService();
export default headerService;
