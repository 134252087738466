import { httpTokenCommon } from "../helper/http-common";

class EliteCardService {
  getO2wTileDashboardCountsAPI(inputData) {
    return httpTokenCommon.post("Dashboard/DashboardCount", inputData);
  }
  getPaymentTileCountsAPI(inputData) {
    return httpTokenCommon.post("Payment/GetPaymentCount", inputData);
  }
  getLogisticTileCountsAPI(inputData) {
    return httpTokenCommon.post("Logistic/GetLogisticCount", inputData);
  }
}

const eliteCardService = new EliteCardService();
export default eliteCardService;
