import React, { Fragment, useState, useEffect, useRef } from "react";
import { Card, Tabs, Button, Select, Spin, Table } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import GlobalFilterDrawer from "../../../components/GlobalFilterDrawer";
import CustomerTab from "./PaymentTabs/CustomerTab";
import CustomerAgingTbl from "./PaymentTabs/CustomerAgingTbl";
import InvoiceDetailsTbl from "./PaymentTabs/InvoiceDetailsTbl";
import {
  imgBank,
  imgFilter,
  paymentSmall_1,
  paymentSmall_2,
  paymentSmall_3,
  paymentSmall_4,
} from "../imageAssets";
import paymentService from "../../../services/Payment/payment-service";
import eliteCardService from "../../../services/elite-cards-service";
import InvoiceOutstandingBarChart from "./PaymentTabs/InvoiceOutstandingBarChart";
import formatNumber from "../../../utils/formatNumber";
import { PayloadForApplyFilterData } from "../../../components/PayloadForApplyFilterData";

const { TabPane } = Tabs;
const { Option } = Select;

const PaymentDashboard = () => {
  const isFirstRender = useRef(true);
  const companyDetails = useSelector(
    (state) => state.CompanyDetails.companyDetials
  );

  const userDetails = useSelector(
    (state) => state.loginReducer.loginDetials.userDetails
  );

  const selectedCustomerAgingData = useSelector(
    (state) => state.PaymentTableFilter.selectedRow
  );

  const [isLoading, setIsLoading] = useState(false);
  const [customerAgingLoading, setCustomerAgingLoading] = useState(false);
  const [isGlobalFilter, setIsGlobalFilter] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isEmployee, setIsEmployee] = useState(false);
  const [applyFilterObj, setApplyFilterObj] = useState({});
  const [selectAnalysisType, setSelectAnalysisType] = useState("C");
  const [invoiceOutStandingData, setInvoiceOutStandingData] = useState([]);
  const [customerAgingData, setCustomerAgingData] = useState([]);
  const [invoiceDetailsData, setInvoiceDetailsData] = useState([]);
  const [totalAmountLCYCount, setTotalAmountLCYCount] = useState(0);
  const [tableObjColumn, setTableObjColumn] = useState({
    sortColumn: "",
    orderBy: "",
    currentPage: "",
    table: "",
  });
  const [distributionChannelData, setDistributionChannelData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [priceGroupData, setPriceGroupData] = useState([]);
  const [salesOfficeData, setSalesOfficeData] = useState([]);
  const [selectedDistributionIds, setSelectedDistributionIds] = useState([]);
  const [selectedCountryIds, setSelectedCountryIds] = useState([]);
  const [selectedPriceGroupIds, setSelectedPriceGroupIds] = useState([]);
  const [selectedSalesOfficeIds, setSelectedSalesOfficeIds] = useState([]);
  const [isSelectDistributionAll, setIsSelectDistributionAll] = useState(false);
  const [isSelectCountryAll, setIsSelectCountryAll] = useState(false);
  const [isSelectPriceGroupAll, setIsSelectPriceGroupAll] = useState(false);
  const [isSelectSalesOfficeAll, setIsSelectSalesOfficeAll] = useState(false);
  const [selectedBarFilter, setSelectedBarFilter] = useState([]);
  const [yearsData, setYearsData] = useState([]);
  const [selectYears, setSelectYears] = useState(null);
  const [selectQuater, setSelectQuater] = useState(null);
  const [customerOutstandingAvg, setCustomerOutstandingAvg] = useState({});
  const [isOutStandingLoading, setIsOutStandingLoading] = useState(false);
  const [isDashboardLoading, setIsDashboardLoading] = useState(false);
  const [dashboardCounts, setDashboardCounts] = useState({});

  useEffect(() => {
    if (Object.keys(applyFilterObj).length > 0) {
      handleGetPaymentTileCount();
      handleGetCustomerInvoiceOutstandingData();
      handleGetCustomerAgingDetailsAPI();
      handleGetInvoiceDetailsAPI();
      handleGetPaymentDashboardCount();
    }
  }, [applyFilterObj]);

  useEffect(() => {
    handleGetPaymentDashboardCount();
    handleGetCustomerInvoiceOutstandingData();
  }, [selectedCustomerAgingData]);

  useEffect(() => {
    if (isFirstRender.current) {
      handleGetDistributionchannelData();
      handleGetCountryData();
      handleGetPriceGroupData();
      handleGetSalesOfficeData();
    }
  }, [
    selectAnalysisType.length === 0 ||
      selectedDistributionIds.length === 0 ||
      selectedCountryIds.length === 0 ||
      selectedPriceGroupIds.length === 0 ||
      selectedSalesOfficeIds.length === 0 ||
      selectedBarFilter.length === 0,
  ]);

  useEffect(() => {
    setIsEmployee(userDetails.isEmployee);
    const { table } = tableObjColumn;

    if (table) {
      if (table === "Customer Aging") {
        handleGetCustomerAgingDetailsAPI();
      } else if (table === "Invoice Details") {
        handleGetInvoiceDetailsAPI();
      }
    }

    handleGetPaymentTileCount();
    handleGetCustomerAgingDetailsAPI();
    handleGetInvoiceDetailsAPI();

    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else if (
      // Object.keys(applyFilterObj).length > 0 ||
      selectedCustomerAgingData ||
      selectAnalysisType.length > 0 ||
      selectedDistributionIds.length > 0 ||
      selectedCountryIds.length > 0 ||
      selectedPriceGroupIds.length > 0 ||
      selectedSalesOfficeIds.length > 0 ||
      selectedBarFilter.length > 0
    ) {
      handleFetchYearsDataAPI();
      handleGetPaymentDashboardCount();
      handleGetPaymentTileCount();
      handleGetCustomerInvoiceOutstandingData();
    }
  }, [
    selectAnalysisType,
    selectedDistributionIds,
    selectedCountryIds,
    selectedPriceGroupIds,
    selectedSalesOfficeIds,
    selectedBarFilter,
    tableObjColumn,
    selectYears,
    selectQuater,
    selectedCustomerAgingData,
  ]);

  const handleApplyFilter = (objData) => {
    setApplyFilterObj(objData);
  };
  const showFilterDrawer = () => {
    setDrawerOpen((prevDrawerOpen) => !prevDrawerOpen);
    setIsGlobalFilter(!isGlobalFilter);
  };
  //// ---------------API Calling Function code start
  //// handle set params
  const handleAPIParams = (check) => {
    const inputData = {
      company_Code: companyDetails ? companyDetails.company_Code : "",
      ...(check === "Aging" ||
      check === "Invoice" ||
      check === "Years" ||
      check === "counts"
        ? { type: selectAnalysisType }
        : {}),
      sortcolumn: tableObjColumn !== "" ? tableObjColumn?.sortColumn : "",
      orderBy: tableObjColumn.orderBy !== "" ? tableObjColumn?.orderBy : "desc",
      distributionChannel: selectedDistributionIds.join(","),
      country: selectedCountryIds.join(","),
      priceGroup: selectedPriceGroupIds.join(","),
      salesOffice: selectedSalesOfficeIds.join(","),
      search:
        selectedBarFilter && selectedBarFilter.length > 0
          ? selectedBarFilter.map((item) => item.customerId).join(",")
          : selectedCustomerAgingData
          ? selectedCustomerAgingData
          : "",
      ...(check === "graph"
        ? {
            pageindex: "",
            pagesize: "",
            year: selectYears ? selectYears : null,
            quarter:
              selectQuater === "all"
                ? null
                : selectQuater
                ? selectQuater
                : null,
          }
        : {
            pageindex:
              tableObjColumn.currentPage !== ""
                ? tableObjColumn.currentPage.toString()
                : "1",
            pagesize: "10",
          }),
      distributionChannel: selectedDistributionIds
        ? selectedDistributionIds.join(",")
        : "",
      salesOffice: selectedSalesOfficeIds
        ? selectedSalesOfficeIds.join(",")
        : "",
      priceGroup: selectedPriceGroupIds ? selectedPriceGroupIds.join(",") : "",
      ...PayloadForApplyFilterData(applyFilterObj, companyDetails),
    };
    if (isGlobalFilter) {
      showFilterDrawer();
    }
    return inputData;
  };

  /// Get payment dashboard counts API
  const handleGetPaymentDashboardCount = () => {
    setIsDashboardLoading(true);
    let inputData = handleAPIParams("counts");
    paymentService
      .getPaymentDashboardCountAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finaldata = response.data.responseData;
          if (Object.keys(finaldata).length > 0) {
            setDashboardCounts(finaldata);
          } else {
            setDashboardCounts({});
          }
        } else {
          NotificationManager.error(response.data.message);
        }
        setIsDashboardLoading(false);
      })
      .catch((error) => {
        setIsDashboardLoading(false);
        console.log(error, "Error");
      });
  };
  /// Get Customer And Currency Wise Outstanding listing API
  const handleGetCustomerInvoiceOutstandingData = () => {
    setIsOutStandingLoading(true);
    let inputData = handleAPIParams("graph");
    paymentService
      .getPaymentCustomerOutstandingGraphAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finaldata = response.data.responseData;
          if (finaldata.customerOutstandings?.length > 0) {
            setInvoiceOutStandingData(finaldata.customerOutstandings);
            setCustomerOutstandingAvg(finaldata.customerOutstandingAvg);
          } else {
            setInvoiceOutStandingData([]);
            setCustomerOutstandingAvg({});
          }
        } else {
          NotificationManager.error(response.data.message);
        }
        setIsOutStandingLoading(false);
      })
      .catch((error) => {
        setIsOutStandingLoading(false);
        console.log(error, "Error");
      });
  };
  /// Get Customer Aging Details listing API
  const handleGetCustomerAgingDetailsAPI = () => {
    setCustomerAgingLoading(true);
    let inputData = handleAPIParams("Aging");
    paymentService
      .getPaymentCustomerAgeingDataAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finaldata = response.data.responseData;
          setCustomerAgingData(finaldata);
        } else {
          NotificationManager.error(response.data.message);
        }
        setCustomerAgingLoading(false);
      })
      .catch((error) => {
        setCustomerAgingLoading(false);
        console.log(error, "Error");
      });
  };
  /// Get Invoice Details listing API
  const handleGetInvoiceDetailsAPI = () => {
    setIsLoading(true);
    let inputData = handleAPIParams("Invoice");
    paymentService
      .getPaymentInvoiceDataAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finaldata = response.data.responseData;
          if (finaldata.customerInvoiceWiseList.length > 0) {
            let parentUniqueKeyCounter = 1;
            let childUniqueKeyCounter = 2000;
            
            let finalUpdatedData = finaldata.customerInvoiceWiseList.map((item) => {
              const updateChildren = item.customerAgeingList.map((child) => {
                return { uniqueKey: `child-${childUniqueKeyCounter++}`, ...child };
              });
            
              return {
                uniqueKey: `parent-${parentUniqueKeyCounter++}`,
                ...item,
                children: updateChildren,
              };
            });
            
            let finalValue = {
              customerInvoiceList: finalUpdatedData,
              ...finaldata,
            };
            
            setInvoiceDetailsData(finalValue);
          } else {
            setInvoiceDetailsData([]);
          }
        } else {
          NotificationManager.error(response.data.message);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error, "Error");
      });
  };
  //// handle get payment tile counts API
  const handleGetPaymentTileCount = () => {
    let inputData = {
      type: selectAnalysisType,
      ...PayloadForApplyFilterData(applyFilterObj, companyDetails),
    };
    if (isGlobalFilter) {
      showFilterDrawer();
    }
    eliteCardService
      .getPaymentTileCountsAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let objData = response.data.responseData;
          if (Object.keys(objData).length > 0) {
            setTotalAmountLCYCount(
              objData.totalPendingAmount_LCY
                ? objData.totalPendingAmount_LCY
                : 0
            );
          } else {
            setTotalAmountLCYCount(0);
          }
        } else {
          NotificationManager.error(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };

  //// handle Get Distribution Channel API
  const handleGetDistributionchannelData = () => {
    paymentService
      .getDistributionchannelDataAPI()
      .then((response) => {
        if (response.data.statusCode === 200) {
          let resultData = response.data.responseData;
          if (resultData.length > 0) {
            setDistributionChannelData(resultData);
          } else {
            setDistributionChannelData([]);
          }
        } else {
          NotificationManager.error(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };
  //// handle Get Country Channel API
  const handleGetCountryData = () => {
    paymentService
      .getCountryDataAPI()
      .then((response) => {
        if (response.data.statusCode === 200) {
          let resultData = response.data.responseData;
          if (resultData.length > 0) {
            setCountryData(resultData);
          } else {
            setCountryData([]);
          }
        } else {
          NotificationManager.error(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };
  //// handle Get Price Group API
  const handleGetPriceGroupData = () => {
    paymentService
      .getPrinceGroupDataAPI()
      .then((response) => {
        if (response.data.statusCode === 200) {
          let resultData = response.data.responseData;
          if (resultData.length > 0) {
            setPriceGroupData(resultData);
          } else {
            setPriceGroupData([]);
          }
        } else {
          NotificationManager.error(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };
  //// handle Get Sales Office API
  const handleGetSalesOfficeData = () => {
    paymentService
      .getSalesOfficeDataAPI()
      .then((response) => {
        if (response.data.statusCode === 200) {
          let resultData = response.data.responseData;
          if (resultData.length > 0) {
            setSalesOfficeData(resultData);
          } else {
            setSalesOfficeData([]);
          }
        } else {
          NotificationManager.error(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };
  /// Fetch Years data listing API
  const handleFetchYearsDataAPI = () => {
    let inputData = handleAPIParams("Years");
    paymentService
      .getYearsDataAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finaldata = response.data.responseData;
          if (finaldata.length > 0) {
            setYearsData(finaldata);
          } else {
            setYearsData([]);
          }
        } else {
          NotificationManager.error(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };
  //// ---------------API Calling Function code end

  //// Analysis Type changes
  const handleAnalysisTypeChange = (e) => {
    setSelectAnalysisType(e);
  };

  //// handle select Bar chart data
  const handleSetSelectedBarChartData = (filter) => {
    setSelectedBarFilter(filter);
    setTableObjColumn({
      sortColumn: "",
      orderBy: "",
      currentPage: "",
      table: "",
    });
  };
  //// table sorting column
  const handleTableSortColumn = (data) => {
    setTableObjColumn({
      sortColumn: data.sortColumn,
      orderBy: data.orderBy,
      currentPage: data.currentPage,
      table: data.table,
    });
  };
  //// handle drop-down Distribution channel select all and deselect all
  const handleDistributionOnChange = (value) => {
    if (value.includes("all")) {
      if (isSelectDistributionAll) {
        setSelectedDistributionIds([]);
        setIsSelectDistributionAll(false);
      } else {
        const allIds = distributionChannelData.map((item) => item.id);
        setSelectedDistributionIds(allIds);
        setIsSelectDistributionAll(true);
      }
    } else {
      setSelectedDistributionIds(value);
      setIsSelectDistributionAll(
        value.length === distributionChannelData.length
      );
    }
  };
  //// handle drop-down Country channel select all and deselect all
  const handleCountryOnChange = (value) => {
    if (value.includes("all")) {
      if (isSelectCountryAll) {
        setSelectedCountryIds([]);
        setIsSelectCountryAll(false);
      } else {
        const allIds = countryData.map((item) => item.id);
        setSelectedCountryIds(allIds);
        setIsSelectCountryAll(true);
      }
    } else {
      setSelectedCountryIds(value);
      setIsSelectCountryAll(value.length === countryData.length);
    }
  };
  //// handle drop-down Price group select all and deselect all
  const handlePriceGroupOnChange = (value) => {
    if (value.includes("all")) {
      if (isSelectPriceGroupAll) {
        setSelectedPriceGroupIds([]);
        setIsSelectPriceGroupAll(false);
      } else {
        const allIds = priceGroupData.map((item) => item.id);
        setSelectedPriceGroupIds(allIds);
        setIsSelectPriceGroupAll(true);
      }
    } else {
      setSelectedPriceGroupIds(value);
      setIsSelectPriceGroupAll(value.length === priceGroupData.length);
    }
  };
  //// handle drop-down Sales Office select all and deselect all
  const handleSalesOfficeOnChange = (value) => {
    if (value.includes("all")) {
      if (isSelectSalesOfficeAll) {
        setSelectedSalesOfficeIds([]);
        setIsSelectSalesOfficeAll(false);
      } else {
        const allIds = salesOfficeData.map((item) => item.id);
        setSelectedSalesOfficeIds(allIds);
        setIsSelectSalesOfficeAll(true);
      }
    } else {
      setSelectedSalesOfficeIds(value);
      setIsSelectSalesOfficeAll(value.length === salesOfficeData.length);
    }
  };
  //// handle drop down onChange
  const handleDropDownOnChange = (value, check) => {
    if (check === "year") {
      setSelectYears(value);
    } else {
      setSelectQuater(value);
    }
  };
  //// handle filter reset data with load default data
  const handleResetFilterData = () => {
    setSelectedDistributionIds([]);
    setSelectedCountryIds([]);
    setSelectedPriceGroupIds([]);
    setSelectedSalesOfficeIds([]);
    setIsSelectDistributionAll(false);
    setIsSelectPriceGroupAll(false);
    setIsSelectSalesOfficeAll(false);
  };

  return (
    <Fragment>
      <div className="app-content content paymentModule">
        <div className="content-wrapper">
          <div className="content-body">
            <h5 className="content-header-title d-inline-block">Payment</h5>
            <div className="main-setting">
              <Button
                className={`setting-button ${drawerOpen ? "open" : ""}`}
                icon={<SettingOutlined spin />}
                onClick={() => showFilterDrawer()}
              />
            </div>
            <Spin size="large" spinning={isDashboardLoading}>
              <div className="row">
                <div className={"col-xl-3 col-lg-6 col-12"}>
                  <div className="card bg_info">
                    <div className="card-content">
                      <div className="card-body payment1 images_bg">
                        <h4 className="text-white font24">
                          <img
                            src={paymentSmall_1}
                            className="card_small_img"
                            alt="Img"
                            style={{ width: "14px" }}
                          />
                          <div style={{ display: "grid" }}>
                            <span style={{ fontSize: "20px" }}>
                              Total Invoice
                            </span>
                            <label>
                              {Object.keys(dashboardCounts).length > 0 &&
                                formatNumber(dashboardCounts.totalInvoice)}
                            </label>
                          </div>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"col-xl-3 col-lg-6 col-12"}>
                  <div className="card bg_warning">
                    <div className="card-content">
                      <div className="card-body payment2 images_bg">
                        <h4 className="text-white font24">
                          <img
                            src={paymentSmall_2}
                            className="card_small_img"
                            alt="Img"
                          />
                          <div style={{ display: "grid" }}>
                            <span style={{ fontSize: "20px", zIndex: 2 }}>
                              Total Received Value
                            </span>
                            <label>
                              {Object.keys(dashboardCounts).length > 0 &&
                                formatNumber(dashboardCounts.totalCollection)}
                            </label>
                          </div>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"col-xl-3 col-lg-6 col-12"}>
                  <div className="card bg_danger">
                    <div className="card-content">
                      <div className="card-body payment3 images_bg ">
                        <h4
                          className="text-white font24"
                          style={{ textDecoration: "none" }}
                        >
                          <img
                            src={paymentSmall_3}
                            className="card_small_img"
                            alt="Img"
                          />
                          <div style={{ display: "grid" }}>
                            <span style={{ fontSize: "20px" }}>
                              Outstanding
                            </span>
                            <label>
                              {Object.keys(dashboardCounts).length > 0 &&
                                formatNumber(dashboardCounts.totalOutstanding)}
                            </label>
                          </div>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"col-xl-3 col-lg-6 col-12"}>
                  <div className="card  bg_success">
                    <div className="card-content">
                      <div className="card-body payment4 images_bg">
                        <h4 className="text-white font24">
                          <img
                            src={paymentSmall_4}
                            className="card_small_img"
                            alt="Img"
                          />
                          <div style={{ display: "grid" }}>
                            <span style={{ fontSize: "20px" }}>
                              Total Overdue
                            </span>
                            <label>
                              {Object.keys(dashboardCounts).length > 0 &&
                                formatNumber(dashboardCounts.totalOverDue)}
                            </label>
                          </div>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Spin>
            <div className="row payment_tab payment_card1">
              <div className="col-md-10 col-sm-6 card_10">
                <div className="d-flex justify-content-between pb-30">
                  <label>Outstanding Analysis</label>
                  <span
                    className="reset_filter_spn"
                    onClick={handleResetFilterData}
                  >
                    Reset filter
                    <img src={imgFilter} alt="img" className="pb_5" />
                  </span>
                </div>
                <div className="d-flex" style={{ overflow: "auto" }}>
                  <div className="mr_12 mb-10 ">
                    <Select
                      mode="multiple"
                      value={selectedDistributionIds}
                      optionFilterProp="children"
                      placeholder="Select Distribution"
                      onChange={handleDistributionOnChange}
                      showSearch={true}
                      showArrow
                    >
                      <Option
                        key="all"
                        value="all"
                        label={
                          isSelectDistributionAll
                            ? "Deselect All"
                            : "Select All"
                        }
                      >
                        {isSelectDistributionAll
                          ? "Deselect All"
                          : "Select All"}
                      </Option>
                      {distributionChannelData.map((item, i) => {
                        return (
                          <Option value={item.id} key={i}>
                            {item.channel_Name ? item.channel_Name : "(Blank)"}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                  <div className="mr_12 mb-10">
                    <Select
                      mode="multiple"
                      value={selectedCountryIds}
                      optionFilterProp="children"
                      placeholder="Select Country"
                      onChange={handleCountryOnChange}
                      showSearch={true}
                      showArrow
                    >
                      <Option
                        key="all"
                        value="all"
                        label={
                          isSelectCountryAll ? "Deselect All" : "Select All"
                        }
                      >
                        {isSelectCountryAll ? "Deselect All" : "Select All"}
                      </Option>
                      {countryData.map((item, i) => {
                        return (
                          <Option value={item.id} key={i}>
                            {item.name ? item.name : "(Blank)"}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                  <div className="mr_12 mb-10">
                    <Select
                      mode="multiple"
                      value={selectedPriceGroupIds}
                      optionFilterProp="children"
                      placeholder="Select Price Group"
                      onChange={handlePriceGroupOnChange}
                      showSearch={true}
                      showArrow
                    >
                      <Option
                        key="all"
                        value="all"
                        label={
                          isSelectPriceGroupAll ? "Deselect All" : "Select All"
                        }
                      >
                        {isSelectPriceGroupAll ? "Deselect All" : "Select All"}
                      </Option>
                      {priceGroupData.map((item, i) => {
                        return (
                          <Option value={item.id} key={i}>
                            {item.group_Name ? item.group_Name : "(Blank)"}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                  <div className="mr_12 mb-10">
                    <Select
                      mode="multiple"
                      value={selectedSalesOfficeIds}
                      optionFilterProp="children"
                      placeholder="Select Sales Office"
                      onChange={handleSalesOfficeOnChange}
                      showSearch={true}
                      showArrow
                    >
                      <Option
                        key="all"
                        value="all"
                        label={
                          isSelectSalesOfficeAll ? "Deselect All" : "Select All"
                        }
                      >
                        {isSelectSalesOfficeAll ? "Deselect All" : "Select All"}
                      </Option>
                      {salesOfficeData.map((item, i) => {
                        return (
                          <Option value={item.id} key={i}>
                            {item.location ? item.location : "(Blank)"}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </div>
              </div>
              <div className="col-md-2 col-sm-6 card_2">
                <div className="left_section_card">
                  <img src={imgBank} alt="img" />
                  <label className="count">{totalAmountLCYCount}</label>
                  <span>
                    {isEmployee ? "Total Outstanding LCY" : "Total Outstanding"}
                  </span>
                </div>
              </div>
            </div>
            <div className="row mt_18 payment_card1 ">
              <div className="col-md-10 card_chart payment_tab">
                <div className="dis_flex justify-content-between row">
                  <label className="pt-8 col-xl-3">
                    Invoice vs Outstanding Graph
                  </label>
                  <div className="pl-20 col-xl-6 col-lg-6 col-12 invoice_out ">
                    <Select
                      name="year"
                      optionFilterProp="children"
                      placeholder="Select Year"
                      value={selectYears}
                      onChange={(e) => handleDropDownOnChange(e, "year")}
                      showSearch={false}
                      showArrow
                    >
                      {yearsData.map((item, i) => {
                        return (
                          <Option value={item.year} key={i}>
                            {item.year}
                          </Option>
                        );
                      })}
                    </Select>
                    <Select
                      className="pl-20"
                      optionFilterProp="children"
                      placeholder="Select Quarter"
                      value={selectQuater}
                      onChange={(e) => handleDropDownOnChange(e, "Quater")}
                      showSearch={false}
                      showArrow
                    >
                      <Option key="all" value="all" label="Select All">
                        Select All
                      </Option>
                      <Option value={"1"}>Quarter 1</Option>
                      <Option value={"2"}>Quarter 2</Option>
                      <Option value={"3"}>Quarter 3</Option>
                      <Option value={"4"}>Quarter 4</Option>
                    </Select>
                  </div>
                  <div className="outstandingGraphTable col-xl-3 col-12  col-lg-6 pb-2 dis_flex  ">
                    <table className="custom-table">
                      <thead>
                        <tr>
                          <th colSpan={2}>Average Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Invoice</td>
                          <td>
                            {customerOutstandingAvg.avgTotalInvoice
                              ? formatNumber(
                                  customerOutstandingAvg.avgTotalInvoice
                                )
                              : "0"}
                          </td>
                        </tr>
                        <tr>
                          <td>Collection/Payment</td>
                          <td>
                            {customerOutstandingAvg.avgTotalCollection
                              ? formatNumber(
                                  customerOutstandingAvg.avgTotalCollection
                                )
                              : "0"}
                          </td>
                        </tr>
                        <tr>
                          <td>Outstanding</td>
                          <td>
                            {customerOutstandingAvg.avgTotalOutstanding
                              ? formatNumber(
                                  customerOutstandingAvg.avgTotalOutstanding
                                )
                              : "0"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <InvoiceOutstandingBarChart
                  invoiceOutStandingData={invoiceOutStandingData}
                  customerOutstandingAvg={customerOutstandingAvg}
                  isOutStandingLoading={isOutStandingLoading}
                />
              </div>
            </div>
            <div className="pay_section_1">
              <div className="row payment_tab">
                <div className="col-md-3">
                  <label className="analy_type">Select Analysis Type</label>
                </div>
                <div className="col-md-9">
                  <Tabs
                    activeKey={selectAnalysisType}
                    className="tab_panel"
                    type="card"
                    onChange={handleAnalysisTypeChange}
                  >
                    <TabPane tab="Credit Days" key="C" />
                    <TabPane tab="Overdue" key="O" />
                    <TabPane tab="Planning Due Date" key="P" />
                    <TabPane tab="Total Days" key="T" />
                  </Tabs>
                </div>
              </div>
            </div>
            <Card className="pay_section_2">
              <div className="row">
                <div className="col-md-12">
                  <CustomerTab
                    selectAnalysisType={selectAnalysisType}
                    selectedDistributionIds={selectedDistributionIds}
                    selectedCountryIds={selectedCountryIds}
                    selectedPriceGroupIds={selectedPriceGroupIds}
                    selectedSalesOfficeIds={selectedSalesOfficeIds}
                    SelectedBarChartData={handleSetSelectedBarChartData}
                    isEmployee={isEmployee}
                    applyFilterObj={applyFilterObj}
                  />
                </div>
              </div>
            </Card>
            <div className="pay_section_3">
              <div className="row payment_tab">
                <div className="col-md-4">
                  <label className="analy_type">Customer Aging Details</label>
                </div>
              </div>
            </div>
            <Card className="pay_section_2">
              <div className="row">
                <div className="col-md-12">
                  <CustomerAgingTbl
                    customerAgingData={customerAgingData}
                    customerAgingLoading={customerAgingLoading}
                    OnhandleTableSortColumn={handleTableSortColumn}
                    isEmployee={isEmployee}
                  />
                </div>
              </div>
            </Card>
            <div className="pay_section_3">
              <div className="row payment_tab">
                <div className="col-md-4">
                  <label className="analy_type">Invoice Details</label>
                </div>
              </div>
            </div>
            <Card className="pay_section_2">
              <div className="row">
                <div className="col-md-12">
                  <InvoiceDetailsTbl
                    invoiceDetailsData={invoiceDetailsData}
                    isLoading={isLoading}
                    OnhandleTableSortColumn={handleTableSortColumn}
                    isEmployee={isEmployee}
                  />
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>

      <div className="main-filter">
        <GlobalFilterDrawer
          isGlobalFilter={isGlobalFilter}
          showFilterDrawer={showFilterDrawer}
          isEmployee={isEmployee}
          applyFilter={handleApplyFilter}
        />
      </div>
    </Fragment>
  );
};

export default PaymentDashboard;
