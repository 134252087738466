import axios from "axios";
import useDecryptData from "../utils/DecryptData";

function GetDecryptToken() {
  const DecryptData = useDecryptData();
  let EncryptToken = localStorage.getItem("token");
  let finalToken = DecryptData(EncryptToken);
  return finalToken;
}

///API Base Url
const baseUrl = window.location.hostname.includes("knackgalaxy")
  ? "https://api.knackgalaxy.com/api/"
  : "https://knack-dev-api.brainvire.net/api/";

export const httpCommon = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*"
  }
});

export const httpTokenCommon = axios.create({
  baseURL: baseUrl,
  headers: {
    "Access-Control-Allow-Origin": "*"
  }
});

httpTokenCommon.interceptors.request.use(
  (config) => {
    const token = GetDecryptToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    if (config.data instanceof FormData) {
      config.headers["Content-Type"] = "multipart/form-data";
    } else {
      config.headers["Content-Type"] = "application/json";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

httpTokenCommon.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // Redirect to login page when token expired
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);
