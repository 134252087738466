import { SET_PAYMENTTABLEFILTER } from "../../actions/paymentTableFilter/paymentTableFilterTypes";

const initialState = {
  selectedRow: null,
};

function paymentTableFilterReducer(selectedState = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_PAYMENTTABLEFILTER:
      return { ...selectedState, selectedRow: payload };

    default:
      return selectedState;
  }
}

export default paymentTableFilterReducer;
