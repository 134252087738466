import React, { useState, useEffect, useRef } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import formatNumber from "../../../../utils/formatNumber";

const CustomerBarChart = ({ BarChartData, handleBarClick }) => {
  const PAGE_SIZE =
    BarChartData.length > 500 || BarChartData.length > 400
      ? 100
      : BarChartData.length > 300 || BarChartData.length > 200
      ? 50
      : BarChartData.length > 150 || BarChartData.length > 100
      ? 30
      : BarChartData.length > 80 || BarChartData.length > 60
      ? 20
      : BarChartData.length > 50 || BarChartData.length > 30
      ? 15
      : BarChartData.length > 20
      ? 10
      : BarChartData.length > 10
      ? 12
      : 5; /// data load per page

  const [visibleData, setVisibleData] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const scrollRef = useRef(null);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }
    if (BarChartData && BarChartData.length > 0) {
      setVisibleData(BarChartData.slice(0, PAGE_SIZE));
    } else {
      setVisibleData([]);
    }
  }, [BarChartData]);

  // useEffect(() => {
  //   if (scrollRef.current) {
  //     scrollRef.current.scrollTop = 0;
  //   }
  // }, [visibleData]);
  useEffect(() => {
    const scrollContainer = scrollRef.current;
    scrollContainer?.addEventListener("scroll", handleScroll, {
      passive: true,
    });

    return () => scrollContainer?.removeEventListener("scroll", handleScroll);
  }, [startIndex, BarChartData]);
  useEffect(() => {
    scrollRef.current.scrollTop = 20;
  }, [visibleData]);

  const handleScroll = () => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;

      const isNearTop = scrollTop < 5; // Small tolerance
      const isNearBottom = scrollTop + clientHeight >= scrollHeight - 5;

      if (isNearTop && startIndex > 0) {
        loadPreviousData();
        // scrollRef.current.scrollTop = 50;
        setTimeout(() => {
          scrollRef.current.scrollTop = 50;
        }, 0);
      }
      if (isNearBottom && startIndex + PAGE_SIZE < BarChartData.length) {
        // if (startIndex + PAGE_SIZE < BarChartData.length) {
        loadNextData();
        // }
      }
    }
  };

  const loadNextData = () => {
    const newStartIndex = startIndex + PAGE_SIZE;
    const newVisibleData = BarChartData.slice(
      newStartIndex,
      newStartIndex + PAGE_SIZE
    );
    // setVisibleData((prevData) => [...prevData, ...newVisibleData]);
    setVisibleData(newVisibleData);
    setStartIndex(newStartIndex);
  };

  const loadPreviousData = () => {
    const newStartIndex = Math.max(0, startIndex - PAGE_SIZE);
    const newVisibleData = BarChartData.slice(newStartIndex, startIndex);
    setVisibleData(newVisibleData);
    setStartIndex(newStartIndex);
  };

  const truncateCustomerName = (customerOrganization) => {
    return customerOrganization?.length > 16
      ? customerOrganization.substring(0, 16) + "..."
      : customerOrganization;
  };

  const chartData = visibleData.map((data) => ({
    name: truncateCustomerName(data.customerOrganization),
    totalPendingAmount_LCY: parseFloat(data.totalPendingAmount_LCY),
    currency: data.currency,
    fullName: data.customerOrganization,
    customerId: data.customerId,
  }));

  const chartHeight = Math.max(
    200,
    BarChartData.length > 500 ||
      BarChartData.length > 400 ||
      BarChartData.length > 300 ||
      BarChartData.length > 200 ||
      BarChartData.length > 150
      ? BarChartData?.length * 7
      : BarChartData.length > 140 ||
        BarChartData.length > 120 ||
        BarChartData.length > 100 ||
        BarChartData.length > 60 ||
        BarChartData.length > 50 ||
        BarChartData.length > 40
      ? BarChartData?.length * 15
      : BarChartData.length > 30 || BarChartData.length > 20
      ? BarChartData?.length * 20
      : BarChartData.length > 10 ||
        BarChartData.length > 8 ||
        BarChartData.length > 5
      ? BarChartData?.length * 35
      : 200 ///500
  );

  const CustomYAxisTick = ({ x, y, payload, onClick }) => {
    const handleTickClick = () => {
      onClick(payload);
    };

    return (
      <text
        x={x}
        y={y}
        fill="#000"
        fontSize={12}
        textAnchor="end"
        dominantBaseline="middle"
        onClick={handleTickClick}
      >
        {payload.value}
      </text>
    );
  };

  const onYAxisLabelClick = (payload) => {
    const clickedData = BarChartData.find((data) => {
      return (
        typeof data.customerOrganization === "string" &&
        typeof payload.value === "string" &&
        data.customerOrganization.includes(
          payload.value.replace(/\.{3}$/, "").trim()
        )
      );
    });
    if (clickedData) {
      let tempObj = {
        name: truncateCustomerName(clickedData.customerOrganization),
        totalPendingAmount_LCY: parseFloat(clickedData.totalPendingAmount_LCY),
        currency: clickedData.currency,
        fullName: clickedData.customerOrganization,
        customerId: clickedData.customerId,
      };
      handleBarClick(tempObj);
    }
  };
  return (
    <div className="app">
      <div
        className="pay_cust_chart"
        ref={scrollRef}
        style={{
          overflowY: BarChartData?.length > PAGE_SIZE ? "auto" : "hidden",
          overflowX: "hidden",
          marginTop: "5px",
        }}
        onScroll={handleScroll}
      >
        <ResponsiveContainer width="100%" height={chartHeight}>
          <BarChart
            data={chartData}
            margin={{ top: 5, right: 10, left: -160, bottom: 5 }}
            layout="vertical"
          >
            <XAxis type="number" hide={true} />
            <YAxis
              dataKey="name"
              type="category"
              width={300}
              style={{
                overflow: "visible",
                textOverflow: "inherit",
              }}
              interval={0}
              tick={(props) => (
                <CustomYAxisTick {...props} onClick={onYAxisLabelClick} />
              )}
            />

            <Tooltip
              formatter={(value, name, props) => [
                formatNumber(value) + " " + props.payload.currency,
                "Pending Amount",
              ]}
              labelFormatter={(label, payload) => {
                if (payload && payload.length > 0) {
                  const dataIndex = payload[0]?.payload;
                  return dataIndex?.fullName || label;
                }
                return label;
              }}
            />

            <Bar
              dataKey="totalPendingAmount_LCY"
              fill="#da4453"
              onClick={handleBarClick}
              label={({ x, y, width, height, value }) => {
                const formattedValue = formatNumber(value);
                const textWidth = formattedValue.length * 6;
                let displayText = "";

                if (textWidth <= width) {
                  displayText = formattedValue;
                } else if (width > 0) {
                  if (width < 15) {
                    displayText = "";
                  } else if (width < 18) {
                    displayText = `${formattedValue.slice(0, 1)}`;
                  } else if (width < 20) {
                    displayText = `${formattedValue.slice(0, 1)}.`;
                  } else if (width < 40) {
                    displayText = `${formattedValue.slice(0, 2)}..`;
                  } else {
                    displayText = `${formattedValue.slice(0, 3)}...`;
                  }
                }

                return (
                  <text
                    x={x + 5}
                    y={y + height / 2 + 5}
                    fill="#FFF"
                    fontSize={12}
                    textAnchor="start"
                    dominantBaseline="middle"
                  >
                    {displayText}
                  </text>
                );
              }}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default CustomerBarChart;
