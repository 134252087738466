import React, { Fragment, useState, useEffect, useRef } from "react";

import { Card, Tabs, Button, Spin, Input, Select } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import GlobalFilterDrawer from "../../../components/GlobalFilterDrawer";

const { Option } = Select;
const CreateGatePass = ({ type }) => {
	const userDetails = useSelector(
		(state) => state.loginReducer.loginDetials.userDetails
	);
	const companyDetails = useSelector(
		(state) => state.CompanyDetails.companyDetials
	);

	const [isLoading, setIsLoading] = useState(false);
	const [isCardsLoading, setIsCardsLoading] = useState(false);
	const [isGlobalFilter, setIsGlobalFilter] = useState(false);
	const [drawerOpen, setDrawerOpen] = useState(false);

	const [activeTabs, setActiveTabs] = useState("");
	const [isEmployee, setIsEmployee] = useState(false);
	const [applyFilterObj, setApplyFilterObj] = useState({});

	/// handle tab onchange code

	const handleApplyFilter = (objData) => {
		setApplyFilterObj(objData);
	};
	const showFilterDrawer = () => {
		setDrawerOpen((prevDrawerOpen) => !prevDrawerOpen);
		setIsGlobalFilter(!isGlobalFilter);
	};

	function hasFilterDataExist(obj) {
		return Object.values(obj).some((array) => array.length > 0);
	}
	return (
		<Fragment>
			<div className="dashboard app-content content visitor ">
				<div className="content-wrapper">
					<div className="content-body">
						{hasFilterDataExist(applyFilterObj) && (
							<h5 className="appled_filter">Applied Filters</h5>
						)}
						<h5 className="content-header-title d-inline-block">
							Create Gate Pass
						</h5>
						<div className="main-setting">
							<Button
								className={`setting-button ${drawerOpen ? "open" : ""}`}
								icon={<SettingOutlined spin />}
								onClick={() => showFilterDrawer()}
							/>
						</div>

						<div className="row">
							<div className={"col-xl-4 col-lg-4 col-12"}>
								<div className="card ">
									<div className="card-content">
										<div className="card-body">
											<h4 className="ft-16 fnt_w_bold"> Person Details</h4>
										</div>
									</div>
								</div>
							</div>
							<div className={"col-xl-4 col-lg-4 col-12"}>
								<div className="card ">
									<div className="card-content">
										<div className="card-body ">
											<h4 className="ft-16 fnt_w_bold">
												Transportation Details
											</h4>
										</div>
									</div>
								</div>
							</div>
							<div className={"col-xl-4 col-lg-4 col-12"}>
								<div className="card ">
									<div className="card-content">
										<div className="card-body  ">
											<h4 className="ft-16 fnt_w_bold"> Remarks</h4>
										</div>
									</div>
								</div>
							</div>
						</div>

						<Card>
							{" "}
							<h4 className="ft-16 fnt_w_bold"> Additional Details</h4>
							<div className="d-flex mt-20" style={{ overflow: "auto" }}>
								<div className="mr_12 ">
									<Select
										placeholder="Name"
										style={{ width: 170, borderRadius: "50px" }}
										showArrow>
										<Option value="option1">Option 1</Option>
										<Option value="option2">Option 2</Option>
									</Select>
								</div>
								<div className="mr_12 ">
									<Select placeholder="Lunch" style={{ width: 170 }} showArrow>
										<Option value="option1">Option 1</Option>
										<Option value="option2">Option 2</Option>
									</Select>
								</div>
								<div className="mr_12  ">
									<button
										className="addButton"
										style={{
											width: 32,
											height: 32,
											borderRadius: "50%",
											border: "1px solid #1890ff",
											backgroundColor: "transparent",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											cursor: "pointer",
										}}>
										<span style={{ fontSize: 24, color: "#1890ff" }}>+</span>
									</button>
								</div>
							</div>
						</Card>
					</div>
				</div>
			</div>

			<div className="main-filter">
				<GlobalFilterDrawer
					isGlobalFilter={isGlobalFilter}
					showFilterDrawer={showFilterDrawer}
					isEmployee={isEmployee}
					applyFilter={handleApplyFilter}
				/>
			</div>
		</Fragment>
	);
};

export default CreateGatePass;
