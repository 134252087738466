import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import GalaxyLogo from "../../assets/images/logo-light-sm.png";
import imgCubes from "../../assets/images/cubes_menu.png";
import { useDispatch } from "react-redux";
import { Menu } from "antd";
import "./layout.css";
import {
  setTableSearchInput,
  setTableSortingData,
} from "../../actions/tableListing/tableSortingActionData";

const { SubMenu } = Menu;

const Sidemenu = ({ collapsed, toggleMobileMenuCollapsed }) => {
  const dispatch = useDispatch();
  const [isMobileDevice, setIsMobileDevice] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileDevice(window.innerWidth <= 1025);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleMenuClick = () => {
    if (isMobileDevice) {
      toggleMobileMenuCollapsed();
    }
  };

  const handleTableSortingData = () => {
    dispatch(setTableSearchInput(""));
    dispatch(
      setTableSortingData({
        sortColumn: "customer_PO_Date", /// default sort column
        orderBy: "desc",
        currentPage: 1,
      })
    );
  };
  /// handle Set nul search data
  const handleSetSearchNullData = () => {
    dispatch(setTableSearchInput(""));
  };

  return (
    <nav className="sidenav__section">
      <button
        className="sidebar__close"
        onClick={() => toggleMobileMenuCollapsed()}
      >
        x
      </button>
      <Link to="/galaxyHome" className="logo_section" onClick={handleMenuClick}>
        <img src={GalaxyLogo} className="w-30" alt="Galaxy Logo" />
        <span className="px-1 logoTitle">Galaxy Portal</span>
      </Link>
      <div className="menu-content mt-3">
        <Menu
          mode="inline"
          theme="dark"
          inlineCollapsed={collapsed}
          className="menu-items side_menu"
          onClick={handleMenuClick}
        >
          <SubMenu
            key="elite"
            icon={
              <img src={imgCubes} alt="Elite Icon" className="elite-icon" />
            }
            title={<span>Elite</span>}
          >
            <Menu.Item key="2">
              <Link to="/dashboard/all" onClick={handleTableSortingData}>
                Order To Warehouse
              </Link>
            </Menu.Item>
            <Menu.Item key="3">
              <Link to="/payment/customer">Payment</Link>
            </Menu.Item>
            <Menu.Item key="4">
              <Link to="/logistic/all" onClick={handleSetSearchNullData}>
                Logistics
              </Link>
            </Menu.Item>
          </SubMenu>
        </Menu>
      </div>
    </nav>
  );
};

export default Sidemenu;
