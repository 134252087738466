import { SET_PAYMENTTABLEFILTER } from "./paymentTableFilterTypes";

export const setPaymentTableFilter = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_PAYMENTTABLEFILTER,
      payload: data,
    });
  } catch (error) {}
};
